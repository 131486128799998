import React from 'react';
import { ISelectionItem, Select } from 'components/Form/Select';
import { LookupModel } from 'types/graphql';
import { DocumentNode } from 'graphql';
import { LookupWrapper } from 'components/Lookups/LookupComponentWrapper';

interface IProps<TQuery, TQueryVariables> {
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  extractLookupItems: (query: TQuery) => LookupModel[];
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
  disabled?: boolean | false;
  selectFirst?: boolean | false;
}

export const LookupSelect = <TQuery, TQueryVariables = Record<string, unknown>>(
  props: IProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const handleSelect =
    (lookups: LookupModel[]) => (option?: ISelectionItem) => {
      props.onSelect(lookups.find((x) => x.id === option?.id));
    };

  return (
    <LookupWrapper<TQuery, TQueryVariables>
      extractLookups={props.extractLookupItems}
      selectedValues={!!props.selectedId ? [props.selectedId] : []}
      queryDocument={props.queryDocument}
      queryVariables={props.queryVariables}
    >
      {({ lookups }) => (
        <Select
          selectedId={props.selectedId}
          items={lookups.map((l) => ({ id: l.id, label: l.name }))}
          onSelect={handleSelect(lookups)}
          disabled={props.disabled}
          selectFirst={props.selectFirst}
        />
      )}
    </LookupWrapper>
  );
};
