import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import MasterChecklistItemTable, {
  IMasterChecklistItemTableControl,
} from 'views/MasterChecklist/components/MasterChecklistItemTable';
import {
  IMasterChecklistItemFilterValues,
  MasterChecklistItemFilters,
} from './components/MasterChecklistItemFilters';

export const MasterChecklist = (): JSX.Element => {
  const tableControl = useRef<IMasterChecklistItemTableControl>();
  const history = useHistory();

  const [checkedJournalIds, setCheckedJournalIds] = useState<string[]>([]);
  const [filterValues, setFilterValues] =
    useState<IMasterChecklistItemFilterValues>({});

  const handleSearch = async (values: IMasterChecklistItemFilterValues) => {
    setFilterValues(values);
    tableControl.current?.filter(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({
      filename: 'master-checklist-items.xlsx',
    });
  };

  const handleAddNewItemClick = () => {
    history.push('/journal-entry-master');
  };

  const handleOpenItemClick = (id: number, clone?: boolean) => () => {
    if (clone) {
      history.push(`/journal-entry-master?clone=${id}`);
    } else {
      history.push(`/journal-entry-master/${id}`);
    }
  };

  const handleBulkUpdateClick = () => {
    history.push(
      `/bulk-update?${checkedJournalIds
        .map((id) => `journalId=${id}`)
        .join('&')}`,
    );
  };

  const handleCheckedChange = (journalId: string) => {
    if (!!checkedJournalIds.find((checkedId) => journalId === checkedId)) {
      setCheckedJournalIds(
        checkedJournalIds.filter((checkedId) => checkedId !== journalId),
      );
    } else {
      setCheckedJournalIds([...checkedJournalIds, journalId]);
    }
  };

  return (
    <>
      <Page title="Master Checklist">
        <SectionHeaderRow>
          <Column>
            <h1>Filters</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        <MasterChecklistItemFilters
          onSearch={handleSearch}
          filterValues={filterValues}
        />

        <SectionHeaderRow className="mt-2">
          <Column>
            <h1>Master Checklist</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        <div className="row mb-3 mt-3">
          <Column>
            <ExportToExcelButton onClick={handleExport} />
          </Column>
          <Column className="text-right mt-3">
            <Button
              variant="primary"
              size="lg"
              className="text-uppercase font-weight-bold mr-3 multiline"
              onClick={handleAddNewItemClick}
            >
              Add Checklist Item
            </Button>
            <Button
              variant="warning"
              size="lg"
              className="text-uppercase font-weight-bold"
              disabled={!!!checkedJournalIds.length}
              onClick={handleBulkUpdateClick}
            >
              Bulk Update
            </Button>
          </Column>
        </div>

        <MasterChecklistItemTable
          controlRef={tableControl}
          onOpenItem={handleOpenItemClick}
          checkedJournalIds={checkedJournalIds}
          onItemCheckedChange={handleCheckedChange}
        />
      </Page>
    </>
  );
};
