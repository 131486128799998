"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JeFrequencyLookupEnum = void 0;
var JeFrequencyLookupEnum;
(function (JeFrequencyLookupEnum) {
    JeFrequencyLookupEnum[JeFrequencyLookupEnum["Monthly"] = 1] = "Monthly";
    JeFrequencyLookupEnum[JeFrequencyLookupEnum["Quarterly"] = 2] = "Quarterly";
    JeFrequencyLookupEnum[JeFrequencyLookupEnum["Annually"] = 3] = "Annually";
})(JeFrequencyLookupEnum = exports.JeFrequencyLookupEnum || (exports.JeFrequencyLookupEnum = {}));
exports.default = JeFrequencyLookupEnum;
