import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import TruncatingTextWrapperWithPopover from 'components/TruncatingTextWrapperWithPopover';
import React from 'react';
import { IDataTableColumnDefinition } from './IDataTableColumnDefinition';
import { SortDirection } from 'types/graphql';
import styles from './DataTable.module.scss';
import { downloadExcel } from './downloadExcel';

interface IDataTableExportArgs<TItem> {
  items: TItem[];
  filename: string;
  onDone?: () => void;
}

export type DataTableExportFunction<TItem> = (
  args: IDataTableExportArgs<TItem>,
) => Promise<void>;

interface IDataTableProps<TItem, TSortBy> {
  data: TItem[];
  rowClassName?: (item: TItem) => string | undefined;
  columns: IDataTableColumnDefinition<TItem, TSortBy>[];
  onSort?: (sortBy: TSortBy, sortDirection: SortDirection) => void;
  small?: boolean;
  striped?: boolean;
  sort?: {
    sortBy?: TSortBy;
    sortDirection?: SortDirection;
  };
  exportRef?: (fn: DataTableExportFunction<TItem>) => void;
  getDataItemId: (item: TItem, index: number) => any;
}

const handleSort =
  <TItem, TSortBy>(props: IDataTableProps<TItem, TSortBy>, sortBy: TSortBy) =>
  () => {
    if (props.onSort) {
      let sortDirection: SortDirection = SortDirection.Asc;
      if (props.sort?.sortBy === sortBy) {
        sortDirection =
          (props.sort?.sortDirection ?? SortDirection.Asc) === SortDirection.Asc
            ? SortDirection.Desc
            : SortDirection.Asc;
      }
      props.onSort(sortBy, sortDirection);
    }
  };

const createTableHeaderColumn = <TItem, TSortBy>(
  props: IDataTableProps<TItem, TSortBy>,
  column: IDataTableColumnDefinition<TItem, TSortBy>,
) => {
  if (column.sortBy) {
    return (
      <th
        key={column.dataFieldName}
        className={`sortable pointer ${
          column.cellClassName ? column.cellClassName() ?? '' : ''
        } ${column.headClassName ?? ''}`}
        style={{
          width: column.width,
          maxWidth: column.width,
          minWidth: column.width,
        }}
        onClick={handleSort(props, column.sortBy)}
      >
        {column.heading}

        {props.sort?.sortBy === column.sortBy &&
          (props.sort?.sortDirection === SortDirection.Desc ? (
            <FontAwesomeIcon
              className={`${styles['sort-direction-icon']}`}
              icon={faAngleDown}
            />
          ) : (
            <FontAwesomeIcon
              className={`${styles['sort-direction-icon']}`}
              icon={faAngleUp}
            />
          ))}
      </th>
    );
  }

  return (
    <th
      key={column.dataFieldName}
      className={`${column.cellClassName ? column.cellClassName() ?? '' : ''} ${
        column.headClassName ?? ''
      }`}
      style={{
        width: column.width,
        maxWidth: column.width,
        minWidth: column.width,
      }}
    >
      {column.heading}
    </th>
  );
};

const DataTable = <TItem, TSortBy = unknown>(
  props: IDataTableProps<TItem, TSortBy>,
): JSX.Element => {
  const tableClassNames = [];
  if (props.small) {
    tableClassNames.push('table-sm');
  }
  if (props.striped) {
    tableClassNames.push('table-striped');
  }

  const exportToExcel = async (
    args: IDataTableExportArgs<TItem>,
  ): Promise<void> => {
    const columns = props.columns.filter(
      (c) => c.excelExport && !c.excludeFromExport,
    );
    return downloadExcel({
      filename: args.filename,
      columns,
      data: args.items,
      onDone: args.onDone,
    });
  };

  if (props.exportRef) {
    props.exportRef(exportToExcel);
  }

  return (
    <table
      className={`data-table ${styles['data-table']} table ${
        tableClassNames.length ? tableClassNames.join(' ') : ''
      }`}
    >
      <thead>
        <tr>
          {props.columns.map((column) =>
            createTableHeaderColumn(props, column),
          )}
        </tr>
      </thead>
      <tbody>
        {props.data.map((rowData, index) => {
          return (
            <tr
              key={
                props.getDataItemId
                  ? props.getDataItemId(rowData, index)
                  : index
              }
              className={
                props.rowClassName ? props.rowClassName(rowData) : undefined
              }
            >
              {props.columns.map((column) => {
                return (
                  <td
                    key={column.dataFieldName}
                    className={`${
                      column.cellClassName
                        ? column.cellClassName(rowData) ?? ''
                        : ''
                    }`}
                    style={{
                      width: column.width,
                      maxWidth: column.width,
                      minWidth: column.width,
                    }}
                  >
                    {column.truncate ? (
                      <TruncatingTextWrapperWithPopover>
                        {column.render(rowData)}
                      </TruncatingTextWrapperWithPopover>
                    ) : (
                      column.render(rowData)
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
