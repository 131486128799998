import { ChipSelect, IChipOption } from 'components/ChipSelect';
import { DocumentNode } from 'graphql';
import React from 'react';
import { StringLookupModel } from 'types/graphql';
import { StringLookupWrapper } from './StringLookupComponentWrapper';

interface IProps<TQuery, TQueryVariables> {
  options?: IChipOption[];
  selectedIds?: string[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  extractStringLookups: (query: TQuery) => StringLookupModel[];
  placeholder?: string;
}

export const StringLookupChipSelect = <TQuery, TQueryVariables>(
  props: IProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const toChipOption = (lookup: StringLookupModel): IChipOption => {
    return {
      id: lookup.name,
      label: lookup.name,
    };
  };

  return (
    <StringLookupWrapper<TQuery, TQueryVariables>
      queryDocument={props.queryDocument}
      queryVariables={props.queryVariables}
      selectedValues={props.selectedIds}
      extractStringLookups={props.extractStringLookups}
    >
      {({ lookups, selectedStringLookups }) => (
        <ChipSelect
          availableOptions={lookups.map(toChipOption)}
          selectedOptions={selectedStringLookups.map(toChipOption)}
          onSelect={props.onChange}
          placeholder={props.placeholder}
        />
      )}
    </StringLookupWrapper>
  );
};
