import React from 'react';
import { Form } from 'react-bootstrap';

export interface ITextInputProps {
  value: string;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  maxLength?: number;
  readOnly?: boolean;
  placeholder?: string;
}

export const TextInput = (props: ITextInputProps): JSX.Element => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur(event.target.value);
    }
  };

  return (
    <Form.Control
      type="text"
      className={props.className}
      value={props.value}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      disabled={props.disabled}
      maxLength={props.maxLength}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
    />
  );
};

export default TextInput;
