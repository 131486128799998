import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useRef, useState } from 'react';
import JournalEntryTable, {
  IJournalEntryTableControl,
} from './components/JournalEntriesTable';
import {
  IJournalEntryFilterValues,
  ReviewJournalEntryFilters,
} from './components/ReviewJournalEntryFilters';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';

export const ReviewJournalEntries = (): JSX.Element => {
  const tableControl = useRef<IJournalEntryTableControl>();
  const [filterValues, setFilterValues] = useState<IJournalEntryFilterValues>(
    {},
  );

  const handleSearch = async (values: IJournalEntryFilterValues) => {
    setFilterValues(values);
    tableControl.current?.filter(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({ filename: 'journal-entries.xlsx' });
  };

  return (
    <>
      <Page title="Journal Entry Review">
        <SectionHeaderRow>
          <Column>
            <h1>Filters</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        <ReviewJournalEntryFilters
          onSearch={handleSearch}
          filterValues={filterValues}
        ></ReviewJournalEntryFilters>
        {Object.keys(filterValues).length > 0 && (
          <>
            <SectionHeaderRow>
              <Column>
                <h1>Journal Entries</h1>
              </Column>
            </SectionHeaderRow>
            <hr />
            <div className="row">
              <Column className="text-left">
                <ExportToExcelButton onClick={handleExport} />
              </Column>
            </div>
          </>
        )}
        <JournalEntryTable controlRef={tableControl} />
      </Page>
    </>
  );
};
