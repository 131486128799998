import { DocumentNode } from 'graphql';
import React, { useEffect, useState } from 'react';
import { LookupModel } from 'types/graphql';
import useApolloClient from 'useApolloClient';

interface ILookupWrapperProps<TQuery, TQueryVariables> {
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  selectedValues?: number[];
  extractLookups: (query: TQuery) => LookupModel[];
  children: (params: {
    lookups: LookupModel[];
    selectedLookups: LookupModel[];
  }) => JSX.Element;
}

export const LookupWrapper = <TQuery, TQueryVariables>(
  props: ILookupWrapperProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const { client } = useApolloClient();
  const [lookups, setLookups] = useState<LookupModel[]>([]);

  const fetchLookups = () => {
    client
      .query<TQuery, TQueryVariables>({
        query: props.queryDocument,
        variables: props.queryVariables,
        fetchPolicy: 'no-cache',
      })
      .then((value) => {
        setLookups(props.extractLookups(value.data));
      });
  };

  useEffect(fetchLookups, []);

  const selectedLookups = lookups.filter((option) => {
    return (
      props.selectedValues?.length &&
      props.selectedValues?.indexOf(option.id) !== -1
    );
  });

  return <>{props.children({ lookups, selectedLookups })}</>;
};
