import React, { useState } from 'react';
import useModalAlerts from '../Alerts/useModalAlerts';
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const DueDaysAfterCloseInfo: React.FC = () => {
  const [alertModal, setAlertModal] = useState<any>();
  const { info } = useModalAlerts(setAlertModal);

  const handleDueDaysAfterCloseInfo = () => {
    info({
      title: 'Number of Days After Close',
      message: (
        <>
          <p>
            Enter one number for either From and/or To, or enter both numbers.
          </p>
          <p>
            If only a number for "From" is entered, JEC will find all matches
            where Days After Close is equal to, or greater than the number
            entered.
          </p>
          <p>
            If only a number for "To" is entered, JEC will find all matches
            where Days After Close is equal to, or lesser than the number
            entered.
          </p>
          <p>
            If numbers are entered for both, JEC will find all matches where
            Days After Close is equal to the lesser number up to and including
            (equal to) the larger number.
          </p>
        </>
      ),
    });
  };

  return (
    <>
      {alertModal}
      <FontAwesomeIcon
        icon={faInfoCircle}
        onClick={handleDueDaysAfterCloseInfo}
        className="pointer"
      />
    </>
  );
};
