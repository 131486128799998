import React from 'react';
import { ISelectionItem, Select } from './Select';

interface IProps {
  value?: number | null;
  onSelect: (value?: number | null) => void;
}

export const MonthSelect = (props: IProps): JSX.Element => {
  const handleMonthSelect = (item?: ISelectionItem) => {
    if (typeof item?.id === 'string') {
      return;
    }

    props.onSelect(item?.id);
  };

  return (
    <Select
      items={[
        { id: null, label: '' },
        { id: 1, label: 'January' },
        { id: 2, label: 'February' },
        { id: 3, label: 'March' },
        { id: 4, label: 'April' },
        { id: 5, label: 'May' },
        { id: 6, label: 'June' },
        { id: 7, label: 'July' },
        { id: 8, label: 'August' },
        { id: 9, label: 'September' },
        { id: 10, label: 'October' },
        { id: 11, label: 'November' },
        { id: 12, label: 'December' },
      ]}
      selectedId={props.value}
      onSelect={handleMonthSelect}
    />
  );
};
