import React from 'react';

interface IProps {
  collapsed?: boolean;
  className?: string;
  children: any;
}

export const SectionHeaderRow = (props: IProps): JSX.Element => {
  return (
    <div
      className={`row grid-section-header-row ${props.className ?? ''} ${
        props.collapsed ? 'collapsed' : ''
      }`}
    >
      {props.children}
    </div>
  );
};

export default SectionHeaderRow;
