import React from 'react';
import { Form } from 'react-bootstrap';
import { ITextInputProps } from './TextInput';

interface IProps extends ITextInputProps {
  rows?: number;
}

export const TextAreaInput = (props: IProps): JSX.Element => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  return (
    <Form.Control
      as="textarea"
      className={props.className}
      value={props.value}
      onChange={handleOnChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
      readOnly={props.readOnly}
      placeholder={props.placeholder}
      rows={props.rows ?? 3}
    />
  );
};

export default TextAreaInput;
