import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAlt,
  faCommentAltLines,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { TextAreaInput } from 'components/Form/TextAreaInput';
import { Column } from 'layouts/components/Grid/Column';
import { SectionHeaderRow } from 'layouts/components/Grid/SectionHeaderRow';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  UpdateJournalEntryDocument,
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';

interface IProps {
  id: number;
  journalId: string;
  comment: string | null;
  readonly?: boolean;
  onCommentSaved?: () => void;
}

export const JournalEntryComment = (props: IProps): JSX.Element => {
  const { client } = useApolloClient();
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState(props.comment ?? '');
  const [alertModal, setAlertModal] = useState<React.ReactNode | null>(null);
  const { apolloError } = useModalAlerts(setAlertModal);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setComment(props.comment ?? '');
    setShowModal(false);
  };

  const handleSave = () => {
    client
      .mutate<UpdateJournalEntryMutation, UpdateJournalEntryMutationVariables>({
        mutation: UpdateJournalEntryDocument,
        variables: {
          input: {
            id: props.id,
            comment,
          },
        },
      })
      .then(() => {
        setShowModal(false);
        if (props.onCommentSaved) {
          props.onCommentSaved();
        }
      })
      .catch((error) => {
        apolloError({ error });
      });
  };

  return (
    <>
      <span className="pointer" onClick={handleShowModal}>
        {!!props.comment ? (
          <FontAwesomeIcon icon={faCommentAltLines} />
        ) : props.readonly ? (
          <></>
        ) : (
          <FontAwesomeIcon icon={faCommentAlt} />
        )}
      </span>
      {alertModal}
      <Modal
        show={showModal}
        backdrop="static"
        centered={true}
        keyboard={false}
      >
        <Modal.Body>
          {!props.readonly && (
            <div className="mb-3">
              <span
                className="float-right text-muted text-small pointer"
                onClick={handleClose}
              >
                Cancel <FontAwesomeIcon icon={faTimes} className="ml-2" />
              </span>
            </div>
          )}
          <SectionHeaderRow>
            <Column>
              <h2>Comments for Journal ID: {props.journalId} </h2>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
          <TextAreaInput
            rows={10}
            value={comment}
            onChange={setComment}
            className="outlined no-resize"
            readOnly={props.readonly}
          ></TextAreaInput>
          <div className="row mt-3">
            <Column className="text-center">
              {!props.readonly && (
                <Button
                  variant="primary"
                  className="mr-3"
                  disabled={comment === props.comment}
                  onClick={handleSave}
                >
                  Save
                </Button>
              )}
              <Button variant="warning" onClick={handleClose}>
                Close
              </Button>
            </Column>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
