import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  onClick?: () => void;
}

export const AddItemIcon = (props: IProps): JSX.Element => {
  return (
    <FontAwesomeIcon
      icon={faPlusCircle}
      className="text-primary pointer"
      title="Add"
      onClick={props.onClick}
    />
  );
};
