import AppVersion from 'components/AppVersion';
import React from 'react';
import MenuDropdown from '../MenuDropdown';

export const SiteHeader = (): JSX.Element => {
  return (
    <header className="site-header container-fluid">
      <div className="row">
        <div className="col">
          <MenuDropdown />
        </div>
        <div className="col text-right">
          Version: <AppVersion />
        </div>
      </div>
    </header>
  );
};

export default SiteHeader;
