import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import { JournalEntryComment } from 'components/JournalEntryComment';
import {
  adjustToLocalDate,
  standardFormatDate,
} from 'jec-common/lib/utils/date-utils';
import Column from 'layouts/components/Grid/Column';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  JournalEntryFilter,
  JournalEntrySelectionType,
  JournalEntrySortBy,
  JournalEntryTableDataDocument,
  JournalEntryTableDataItemFragment,
  JournalEntryTableDataQuery,
  JournalEntryTableDataQueryVariables,
  MonthlyChecklistItemViewFragment,
  SkippedJournalEntryTableDataDocument,
  SkippedJournalEntryTableDataQuery,
  SkippedJournalEntryTableDataQueryVariables,
  SortDirection,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { IJournalEntryFilterValues } from '../StatusReportFilters';
import { MonthlyChecklistItemComment } from 'components/MonthlyChecklistItemComment';

export interface IJournalEntryTableControl {
  filter: (values: IJournalEntryFilterValues) => void;
  refresh: () => void;
  export: (args: { filename: string }) => void;
}

interface IProps {
  controlRef?: React.MutableRefObject<IJournalEntryTableControl | undefined>;
}

export const StatusReportTable: React.FC<IProps> = (props: IProps) => {
  const pageSize = 20;
  const { client } = useApolloClient();

  const [totalItems, setTotalItems] = useState<number>(0);
  const [items, setItems] = useState<JournalEntryTableDataItemFragment[]>([]);

  const [sortBy, setSortBy] = useState<JournalEntrySortBy>(
    JournalEntrySortBy.JournalId,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] = useState<IJournalEntryFilterValues>(
    {},
  );

  const [alertModal, setAlertModal] = useState<any>();
  const { apolloError } = useModalAlerts(setAlertModal);

  const fetch = (options: {
    skip?: number;
    take?: number;
    sortBy: JournalEntrySortBy;
    sortDirection: SortDirection;
  }) => {
    if (filterValues?.selectionType !== 'Skipped') {
      return client.query<
        JournalEntryTableDataQuery,
        JournalEntryTableDataQueryVariables
      >({
        query: JournalEntryTableDataDocument,
        variables: {
          filter: filterValues as JournalEntryFilter,
          paginationOptions: {
            skip: options.skip,
            take: options.take,
          },
          sortOptions: {
            sortBy: options.sortBy,
            sortDirection: options.sortDirection,
          },
        },
        fetchPolicy: 'no-cache',
      });
    }

    return client.query<
      SkippedJournalEntryTableDataQuery,
      SkippedJournalEntryTableDataQueryVariables
    >({
      query: SkippedJournalEntryTableDataDocument,
      variables: {
        filter: {
          ...filterValues,
          selectionType: JournalEntrySelectionType.All,
        } as JournalEntryFilter,
        paginationOptions: {
          skip: options.skip,
          take: options.take,
        },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
      fetchPolicy: 'no-cache',
    });
  };

  const fetchNextItemSet = async (
    append = true,
    skip: number = items.length,
    take: number = pageSize,
  ) => {
    if (filterValues?.selectionType === undefined) {
      setTotalItems(0);
      setItems([]);
      return;
    }

    return fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        setTotalItems(value.data.journalEntries.totalItems);
        const itemsWithIdsFixed =
          filterValues?.selectionType !== 'Skipped'
            ? [...value.data.journalEntries.items]
            : [
                ...value.data.journalEntries.items.map(
                  (item) =>
                    ({
                      ...item,
                      id: item.id || item.monthlyChecklistItem.id,
                    } as JournalEntryTableDataItemFragment),
                ),
              ];

        setItems(append ? [...items, ...itemsWithIdsFixed] : itemsWithIdsFixed);
      })
      .catch((reason) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const refresh = async (): Promise<void> => {
    return fetchNextItemSet(false, 0);
  };

  useEffect(() => {
    refresh();
  }, [filterValues, sortBy, sortDirection]);

  const handleSort = (
    sortBy: JournalEntrySortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  let exportFunction: DataTableExportFunction<JournalEntryTableDataItemFragment>;
  const handleExport = (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.journalEntries.items,
        filename: args.filename,
      });
    });
  };

  if (props.controlRef) {
    props.controlRef.current = {
      filter: setFilterValues,
      refresh,
      export: handleExport,
    };
  }

  const getYYYYMM = (args: { year: number; month: number }): number =>
    args.year * 100 + args.month;

  const currentYYYYMM: number = getYYYYMM({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });

  const columns: IDataTableColumnDefinition<
    JournalEntryTableDataItemFragment,
    JournalEntrySortBy
  >[] = [
    {
      dataFieldName: 'period',
      heading: 'Period',
      render: (item: JournalEntryTableDataItemFragment) =>
        `${String(item.monthlyChecklistItem.period?.month).padStart(2, '0')}-${
          item.monthlyChecklistItem.period?.year
        }`,
      sortBy: JournalEntrySortBy.Period,
      excelExport: (item) =>
        `${String(item.monthlyChecklistItem.period?.month).padStart(2, '0')}-${
          item.monthlyChecklistItem.period?.year
        }`,
    },
    {
      dataFieldName: 'daysAfterClose',
      heading: 'Days After Close',
      width: '8rem',
      render: (item) => item.monthlyChecklistItem.dueDaysAfterClose.toString(),
      excelExport: (item) => item.monthlyChecklistItem.dueDaysAfterClose,
      sortBy: JournalEntrySortBy.DueDaysAfterClose,
    },
    {
      dataFieldName: 'entity',
      heading: 'Entity',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.kbsEntity.name,
      sortBy: JournalEntrySortBy.Entity,
      excelExport: (item) => item.monthlyChecklistItem.kbsEntity.name,
    },
    {
      dataFieldName: 'title',
      heading: 'Title',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.title,
      sortBy: JournalEntrySortBy.Title,
      excelExport: (item) => item.monthlyChecklistItem.title,
    },
    {
      dataFieldName: 'type',
      heading: 'Type of Entry',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.jeType.name,
      sortBy: JournalEntrySortBy.JeType,
      excelExport: (item) => item.monthlyChecklistItem.jeType.name,
    },
    {
      dataFieldName: 'journalId',
      heading: 'Journal ID',
      render: (item: JournalEntryTableDataItemFragment) => (
        <>
          {item.journalId}{' '}
          {!!item.monthlyChecklistItem.retireYear &&
            !!item.monthlyChecklistItem.retireMonth &&
            getYYYYMM({
              year: item.monthlyChecklistItem.retireYear,
              month: item.monthlyChecklistItem.retireMonth,
            }) <= currentYYYYMM && (
              <span className="text-danger">(retired)</span>
            )}
        </>
      ),
      sortBy: JournalEntrySortBy.JournalId,
      excelExport: (item) => item.journalId.toString(),
    },
    {
      dataFieldName: 'netsuiteTransactionNumber',
      heading: 'Trans. Num.',
      width: '10rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : item.netsuiteTransactionNumber ?? '',
      sortBy: JournalEntrySortBy.TransactionNumber,
      excelExport: (item) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : item.netsuiteTransactionNumber,
    },
    {
      dataFieldName: 'group',
      heading: 'Group',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.kbsGroup.name,
      sortBy: JournalEntrySortBy.Group,
      excelExport: (item) => item.monthlyChecklistItem.kbsGroup.name,
    },
    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.responsibleUser.name,
      sortBy: JournalEntrySortBy.Responsible,
      excelExport: (item) => item.monthlyChecklistItem.responsibleUser.name,
    },
    {
      dataFieldName: 'completedBy',
      heading: 'Completed By',
      render: (item: JournalEntryTableDataItemFragment) =>
        filterValues?.selectionType === 'Skipped' ? (
          'NA'
        ) : (
          <div className="row">
            <Column width={10}>{item.completedBy || ''}</Column>
            <Column width={2} className="text-right">
              {!!item.completedBy ? (
                <FontAwesomeIcon icon={faCheckSquare} />
              ) : (
                <FontAwesomeIcon icon={faSquare} />
              )}
            </Column>
          </div>
        ),
      sortBy: JournalEntrySortBy.CompletedBy,
      excelExport: (item) =>
        filterValues?.selectionType === 'Skipped' ? 'NA' : item.completedBy,
    },
    {
      dataFieldName: 'postedDate',
      heading: 'Date Posted',
      width: '11rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : item.postedDate !== undefined
          ? standardFormatDate(adjustToLocalDate(item.postedDate))
          : '',
      sortBy: JournalEntrySortBy.PostedDate,
      excelExport: (item) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : adjustToLocalDate(item.postedDate),
    },
    {
      dataFieldName: 'recordedDate',
      heading: 'Rec. Date',
      width: '10rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : standardFormatDate(adjustToLocalDate(item.recordedDate)),
      sortBy: JournalEntrySortBy.RecordedDate,
      excelExport: (item) =>
        filterValues?.selectionType === 'Skipped'
          ? 'NA'
          : adjustToLocalDate(item.recordedDate),
    },
    {
      dataFieldName: 'comment',
      heading: 'Comment',
      width: '8rem',
      cellClassName: () => 'text-center',
      render: (item: JournalEntryTableDataItemFragment) =>
        filterValues?.selectionType === 'Skipped' ? (
          <MonthlyChecklistItemComment
            monthlyChecklistItem={
              {
                monthlyChecklistItemId: item.monthlyChecklistItem.id,
                journalId: item.journalId,
                comment: item.comment,
              } as MonthlyChecklistItemViewFragment
            }
            readonly={true}
            onCommentUpdated={() => {
              return Promise.resolve(true);
            }}
          />
        ) : (
          <JournalEntryComment
            id={item.id}
            journalId={item.journalId}
            comment={item.comment ?? null}
            readonly={true}
          />
        ),
      excelExport: (item) => item.comment,
    },
  ];
  if (
    Object.keys(filterValues).length === 0 ||
    filterValues?.selectionType === null
  ) {
    return <></>;
  }
  return (
    <>
      {alertModal}
      <InfiniteScroll
        dataLength={items.length}
        next={fetchNextItemSet}
        hasMore={items.length < totalItems}
        loader={<span>loading more checklist rows...</span>}
        endMessage={<span></span>}
      >
        <DataTable<JournalEntryTableDataItemFragment, JournalEntrySortBy>
          columns={columns}
          data={items}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          exportRef={(fn) => {
            exportFunction = fn;
          }}
        ></DataTable>
      </InfiniteScroll>
    </>
  );
};

export default StatusReportTable;
