import {
  IMonthAndYearSelection,
  MonthAndYearSelect,
} from 'components/Form/MonthAndYearSelect';
import { PeriodStatusLookupEnum } from 'jec-common';
import Column from 'layouts/components/Grid/Column';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  PeriodSelectionDocument,
  PeriodSelectionQuery,
  PeriodSelectionQueryVariables,
  PeriodSelectionRangeDocument,
  PeriodSelectionRangeQuery,
  PeriodSelectionRangeQueryVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';

interface IProps {
  onPeriodChange: (
    selectedPeriod: ISelectedPeriod,
    allChecklistItems: boolean,
  ) => void;
  refreshPeriodRef?: (fn: () => void) => void;
}

export interface ISelectedPeriod {
  month: number;
  year: number;
  periodStatus?: PeriodStatusLookupEnum;
  closeDate?: Date;
  closedDate?: Date;
  initiatedDate?: Date;
}

export const PeriodSelection = (props: IProps): JSX.Element => {
  const [month, setMonth] = useState<number | null>();
  const [year, setYear] = useState<number | null>();
  const [earliestYear, setEarliestYear] = useState<number | undefined>();

  const [allChecklistItems, setAllChecklistItems] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState<ISelectedPeriod | null>(
    null,
  );

  const { client } = useApolloClient();

  const fetchPeriod = async (fetchMonth?: number, fetchYear?: number) => {
    const m = fetchMonth ?? month;
    const y = fetchYear ?? year;

    if (!!m && !!y) {
      const response = await client.query<
        PeriodSelectionQuery,
        PeriodSelectionQueryVariables
      >({
        query: PeriodSelectionDocument,
        variables: {
          month: m,
          year: y,
        },
        fetchPolicy: 'no-cache',
      });

      const selectedPeriod: ISelectedPeriod = {
        month: m,
        year: y,
      };

      if (response.data.period) {
        selectedPeriod.month = m;
        selectedPeriod.year = y;
        selectedPeriod.periodStatus = response.data.period.periodStatus
          ? response.data.period.periodStatus.id
          : undefined;
        selectedPeriod.closeDate = response.data.period.closeDate;
        selectedPeriod.closedDate = response.data.period.closedDate;
        selectedPeriod.initiatedDate = response.data.period.initiatedDate;
      }

      setSelectedPeriod(selectedPeriod);
      props.onPeriodChange(selectedPeriod, allChecklistItems);
    }
  };

  useEffect(() => {
    client
      .query<PeriodSelectionRangeQuery, PeriodSelectionRangeQueryVariables>({
        query: PeriodSelectionRangeDocument,
        fetchPolicy: 'network-only',
      })
      .then((result) => {
        setYear(result.data.periodSelectionRange.latestYear);
        setMonth(result.data.periodSelectionRange.latestMonth);
        setEarliestYear(result.data.periodSelectionRange.earliestYear);
        fetchPeriod(
          result.data.periodSelectionRange.latestMonth,
          result.data.periodSelectionRange.latestYear,
        );
      });
  }, []);

  if (props.refreshPeriodRef) {
    props.refreshPeriodRef(fetchPeriod);
  }

  const handlePeriodChange = async (value?: IMonthAndYearSelection) => {
    setMonth(value?.month);
    setYear(value?.year);

    if (!!value?.month && !!value?.year) {
      await fetchPeriod(value.month, value.year);
    }
  };

  const handleAllChecklistItemsChanged = (value: boolean) => () => {
    setAllChecklistItems(value);

    if (!!selectedPeriod) {
      props.onPeriodChange(selectedPeriod, value);
    }
  };

  return (
    <>
      <div className="row">
        <FormLabelColumn width={4}>Select Period</FormLabelColumn>
        <Column width={8}>
          <MonthAndYearSelect
            earliestYear={earliestYear}
            monthValue={month}
            yearValue={year}
            onSelect={handlePeriodChange}
          />
        </Column>
      </div>

      <div className="row mt-3">
        <Column className="font-weight-bold">
          <Form.Check
            type="radio"
            label="Only Scheduled Checklist Items"
            checked={!allChecklistItems}
            onChange={handleAllChecklistItemsChanged(false)}
          />
          <Form.Check
            type="radio"
            label="All Checklist Items"
            className="mt-1"
            checked={allChecklistItems}
            onChange={handleAllChecklistItemsChanged(true)}
          />
        </Column>
      </div>
    </>
  );
};
