import { useAuthContext } from 'auth/AuthContext';
import React from 'react';

interface SecuredContentProps {
  role?: string;
  children: any;
}

const SecuredContent = ({
  role,
  children,
}: SecuredContentProps): JSX.Element => {
  const { isAuthenticated, user } = useAuthContext();

  if (!isAuthenticated) {
    return <></>;
  }

  if (role && !user?.roles.includes(role)) {
    return <></>;
  }

  return <>{children}</>;
};

export default SecuredContent;
