import { DocumentNode } from 'graphql';
import React from 'react';
import {
  LookupModel,
  UserLookupsDocument,
  UserLookupsQuery,
  UserLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  includeInactive?: boolean;
  includeId?: number;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: UserLookupsQueryVariables;
    extractLookups: (query: UserLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const UserLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: UserLookupsDocument,
        queryVariables: {
          includeId: props.includeId,
          includeDeleted: props.includeDeleted,
          includeInactive: props.includeInactive,
        },
        extractLookups: (query) => query.userLookups,
      })}
    </>
  );
};
