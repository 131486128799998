import { DocumentNode } from 'graphql';
import React from 'react';
import {
  JeTypeLookupsDocument,
  JeTypeLookupsQuery,
  JeTypeLookupsQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: JeTypeLookupsQueryVariables;
    extractLookups: (query: JeTypeLookupsQuery) => LookupModel[];
  }) => JSX.Element;
}

export const JeTypeLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: JeTypeLookupsDocument,
        queryVariables: { includeDeleted: props.includeDeleted },
        extractLookups: (query) => query.jeTypes,
      })}
    </>
  );
};
