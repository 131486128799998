import { DocumentNode } from 'graphql';
import React from 'react';
import {
  KbsEntityLookupsDocument,
  KbsEntityLookupsQuery,
  KbsEntityLookupsQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: KbsEntityLookupsQueryVariables;
    extractLookups: (query: KbsEntityLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const KbsEntityLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: KbsEntityLookupsDocument,
        queryVariables: { includeDeleted: props.includeDeleted },
        extractLookups: (query) => query.kbsEntities,
      })}
    </>
  );
};
