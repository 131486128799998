import { DocumentNode } from 'graphql';
import React, { useEffect, useState } from 'react';
import { StringLookupModel } from 'types/graphql';
import useApolloClient from 'useApolloClient';

interface IStringLookupWrapperProps<TQuery, TQueryVariables> {
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  selectedValues?: string[];
  extractStringLookups: (query: TQuery) => StringLookupModel[];
  children: (params: {
    lookups: StringLookupModel[];
    selectedStringLookups: StringLookupModel[];
  }) => JSX.Element;
}

export const StringLookupWrapper = <TQuery, TQueryVariables>(
  props: IStringLookupWrapperProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const { client } = useApolloClient();
  const [lookups, setStringLookups] = useState<StringLookupModel[]>([]);

  const fetchStringLookups = () => {
    client
      .query<TQuery, TQueryVariables>({
        query: props.queryDocument,
        variables: props.queryVariables,
        fetchPolicy: 'no-cache',
      })
      .then((value) => {
        setStringLookups(props.extractStringLookups(value.data));
      });
  };

  useEffect(fetchStringLookups, [props.queryVariables]);

  const selectedStringLookups = lookups.filter((option) => {
    return (
      props.selectedValues?.length &&
      props.selectedValues?.indexOf(option.name) !== -1
    );
  });

  return <>{props.children({ lookups, selectedStringLookups })}</>;
};
