import { IChipAutosuggestOption } from 'components/ChipAutosuggest';
import { IChipOption } from 'components/ChipSelect';
import { DatePicker } from 'components/Form/DatePicker';
import { PlainText } from 'components/Form/PlainText';
import TextInput from 'components/Form/TextInput';
import { JournalIdChipAutosuggest } from 'components/JournalIdChipAutosuggest';
import { JeFrequencyChipSelect } from 'components/Lookups/JeFrequencyLookups/JeFrequencyChipSelect';
import { JeTypeChipSelect } from 'components/Lookups/JeTypeLookups/JeTypeChipSelect';
import { KbsEntityChipSelect } from 'components/Lookups/KbsEntityLookups/KbsEntityChipSelect';
import { KbsGroupChipSelect } from 'components/Lookups/KbsGroupLookups/KbsGroupChipSelect';
import { UserChipSelect } from 'components/Lookups/UserLookups/UserChipSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { CheckBlock } from 'components/Form/CheckBlock';
import { IntegerInput } from 'components/Form/IntegerInput';
import { DueDaysAfterCloseInfo } from 'components/DueDaysAfterCloseInfo/DueDaysAfterCloseInfo';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { DateRange } from 'types/graphql';

export interface IMasterChecklistItemFilterValues {
  title?: string | null;
  kbsEntityLookupIdIn?: number[];
  kbsGroupLookupIdIn?: number[];
  jeFrequencyLookupIdIn?: number[];
  responsibleUserIdIn?: number[];
  jeTypeLookupIdIn?: number[];
  journalIdIn?: string[];
  lastExecuted?: DateRange;
  includeRetired?: boolean;
  dueDaysAfterCloseMin?: number;
  dueDaysAfterCloseMax?: number;
}

interface IProps {
  filterValues?: IMasterChecklistItemFilterValues;
  onSearch: (filterValues: IMasterChecklistItemFilterValues) => Promise<void>;
}

export const MasterChecklistItemFilters = (props: IProps): JSX.Element => {
  const [isSearching, setIsSearching] = useState(false);

  const [title, setTitle] = useState<string | null>(null);
  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [selectedFrequencyIds, setSelectedFrequencyIds] = useState<number[]>(
    [],
  );
  const [selectedResponsibleUserIds, setSelectedResponsibleUserIds] = useState<
    number[]
  >([]);
  const [selectedJeTypeLookupIds, setSelectedJeTypeLookupIds] = useState<
    number[]
  >([]);
  const [selectedJournalIds, setSelectedJournalIds] = useState<
    IChipAutosuggestOption[]
  >([]);
  const [lastExecutedFrom, setLastExecutedFrom] = useState<Date | null>(null);
  const [lastExecutedTo, setLastExecutedTo] = useState<Date | null>(null);
  const [dueDaysAfterCloseMin, setDueDaysAfterCloseMin] = useState<
    number | null
  >(null);
  const [dueDaysAfterCloseMax, setDueDaysAfterCloseMax] = useState<
    number | null
  >(null);
  const [displayRetired, setDisplayRetired] = useState<boolean>(false);

  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  const handleEntityFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedEntityIds(options.map((o) => parseInt(o.id)));
  };

  const handleGroupFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedGroupIds(options.map((o) => parseInt(o.id)));
  };

  const handleFrequencyFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedFrequencyIds(options.map((o) => parseInt(o.id)));
  };

  const handleResponsibleUserFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedResponsibleUserIds(options.map((o) => parseInt(o.id)));
  };

  const handleJeTypeFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedJeTypeLookupIds(options.map((o) => parseInt(o.id)));
  };

  const handleDueDaysAfterCloseMinChanged = (value: number | null) => {
    setDueDaysAfterCloseMin(value);
  };

  const handleDueDaysAfterCloseMaxChanged = (value: number | null) => {
    setDueDaysAfterCloseMax(value);
  };

  const handleJournalIdsFilterChanged = (
    options: readonly IChipAutosuggestOption[],
  ) => {
    setSelectedJournalIds(options.map((o) => o));
  };

  const handleClearSearchClick = () => {
    setSelectedEntityIds([]);
    setSelectedJeTypeLookupIds([]);
    setSelectedFrequencyIds([]);
    setSelectedGroupIds([]);
    setSelectedResponsibleUserIds([]);
    setSelectedJournalIds([]);
    setTitle(null);
    setLastExecutedFrom(null);
    setLastExecutedTo(null);
    setDueDaysAfterCloseMin(null);
    setDueDaysAfterCloseMax(null);
    setDisplayRetired(false);
  };

  const handleSearchClick = () => {
    if (
      dueDaysAfterCloseMin !== null &&
      dueDaysAfterCloseMax !== null &&
      dueDaysAfterCloseMin > dueDaysAfterCloseMax
    ) {
      error({
        message:
          'The Number of Days After Close "from" must be less than or the same as "to"',
      });
      return;
    }
    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        kbsEntityLookupIdIn: selectedEntityIds.length
          ? selectedEntityIds
          : undefined,
        jeTypeLookupIdIn: selectedJeTypeLookupIds.length
          ? selectedJeTypeLookupIds
          : undefined,
        jeFrequencyLookupIdIn: selectedFrequencyIds.length
          ? selectedFrequencyIds
          : undefined,
        kbsGroupLookupIdIn: selectedGroupIds.length
          ? selectedGroupIds
          : undefined,
        journalIdIn: selectedJournalIds.length
          ? selectedJournalIds.map((j) => j.label)
          : undefined,
        responsibleUserIdIn: selectedResponsibleUserIds.length
          ? selectedResponsibleUserIds
          : undefined,
        title: !!title ? title : undefined,
        lastExecuted:
          !lastExecutedFrom && !lastExecutedTo
            ? undefined
            : { from: lastExecutedFrom, to: lastExecutedTo },
        dueDaysAfterCloseMin: dueDaysAfterCloseMin ?? undefined,
        dueDaysAfterCloseMax: dueDaysAfterCloseMax ?? undefined,
        includeRetired: displayRetired,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn>Title</FormLabelColumn>
            <Column>
              <TextInput value={title || ''} onChange={setTitle} />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Entity</FormLabelColumn>
            <Column>
              <KbsEntityChipSelect
                selectedIds={selectedEntityIds}
                onChange={handleEntityFilterChanged}
                placeholder="All Entities"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Group</FormLabelColumn>
            <Column>
              <KbsGroupChipSelect
                selectedIds={selectedGroupIds}
                onChange={handleGroupFilterChanged}
                placeholder="All Groups"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Journal ID</FormLabelColumn>
            <Column>
              <JournalIdChipAutosuggest
                selectedOptions={selectedJournalIds}
                onChange={handleJournalIdsFilterChanged}
              />
            </Column>
          </div>
        </FieldSetColumn>
        <FieldSetColumn>
          <div className="row">
            <FormLabelColumn>Frequency</FormLabelColumn>
            <Column>
              <JeFrequencyChipSelect
                selectedIds={selectedFrequencyIds}
                onChange={handleFrequencyFilterChanged}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Responsible</FormLabelColumn>
            <Column>
              <UserChipSelect
                selectedIds={selectedResponsibleUserIds}
                onChange={handleResponsibleUserFilterChanged}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Type of Entry</FormLabelColumn>
            <Column>
              <JeTypeChipSelect
                selectedIds={selectedJeTypeLookupIds}
                onChange={handleJeTypeFilterChanged}
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Last Executed</FormLabelColumn>
            <Column>
              <div className="row">
                <Column width="auto">
                  <PlainText>From</PlainText>
                </Column>
                <Column>
                  <DatePicker
                    value={lastExecutedFrom}
                    onChange={setLastExecutedFrom}
                  />
                </Column>
                <Column width={2} className="text-center">
                  <PlainText>to</PlainText>
                </Column>
                <Column>
                  <DatePicker
                    value={lastExecutedTo}
                    onChange={setLastExecutedTo}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>
              Number of Days After Close <DueDaysAfterCloseInfo />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width="auto">
                  <PlainText>From</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMin}
                    onChange={handleDueDaysAfterCloseMinChanged}
                    minimumValue={0}
                  />
                </Column>
                <Column width={2} className="text-center">
                  <PlainText>to</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMax}
                    onChange={handleDueDaysAfterCloseMaxChanged}
                    minimumValue={0}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn>Display Retired</FormLabelColumn>
            <Column>
              <CheckBlock
                checked={displayRetired}
                onCheckedChange={setDisplayRetired}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
