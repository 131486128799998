import useModalAlerts from 'components/Alerts/useModalAlerts';
import { IChipOption } from 'components/ChipSelect';
import { CompletedByChipSelect } from 'components/Lookups/CompletedByLookups/CompletedByChipSelect';
import { KbsEntityChipSelect } from 'components/Lookups/KbsEntityLookups/KbsEntityChipSelect';
import { KbsGroupChipSelect } from 'components/Lookups/KbsGroupLookups/KbsGroupChipSelect';
import { PeriodEntitySelect } from 'components/Lookups/PeriodLookups/PeriodEntitySelect';
import { UserChipSelect } from 'components/Lookups/UserLookups/UserChipSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import {
  JournalEntryCounts,
  JournalEntryCountsDocument,
  JournalEntryCountsQuery,
  JournalEntryCountsQueryVariables,
  JournalEntryFilter,
  JournalEntrySelectionType,
  LookupModel,
  SkippedJournalEntryCountDocument,
  SkippedJournalEntryCountQuery,
  SkippedJournalEntryCountQueryVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { DueDaysAfterCloseInfo } from 'components/DueDaysAfterCloseInfo/DueDaysAfterCloseInfo';
import { IntegerInput } from 'components/Form/IntegerInput';
import { PlainText } from 'components/Form/PlainText';

export interface IJournalEntryFilterValues {
  periodId?: number;
  periodStartId?: number;
  periodEndId?: number;
  responsibleUserIdIn?: number[];
  kbsEntityLookupIdIn?: number[];
  kbsGroupLookupIdIn?: number[];
  completedByNameIn?: string[];
  selectionType?: JournalEntrySelectionType | 'Skipped';
  dueDaysAfterCloseMin?: number;
  dueDaysAfterCloseMax?: number;
}

export enum PeriodSelectionType {
  period = 1,
  periodRange = 2,
  allPeriods = 3,
  none = 4,
}

interface IProps {
  filterValues?: IJournalEntryFilterValues;
  onSearch: (filterValues: IJournalEntryFilterValues) => Promise<void>;
}

export const StatusReportFilters: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  const [firstPeriodSelectionHasOccurred, setFirstPeriodSelectionHasOccurred] =
    useState<boolean>(false);

  const { client } = useApolloClient();
  const [periodSelectionType, setPeriodSelectionType] =
    useState<PeriodSelectionType>(PeriodSelectionType.period);
  const [selectedPeriodId, setSelectedPeriodId] = useState<number | null>();
  const [selectedPeriodStartId, setSelectedPeriodStartId] = useState<
    number | null
  >();
  const [selectedPeriodEndId, setSelectedPeriodEndId] = useState<
    number | null
  >();
  const [selectedResponsibleUserIds, setSelectedResponsibleUserIds] = useState<
    number[]
  >([]);
  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [selectedCompletedByNames, setSelectedCompletedByNames] = useState<
    string[]
  >([]);
  const [dueDaysAfterCloseMin, setDueDaysAfterCloseMin] = useState<
    number | null
  >(null);
  const [dueDaysAfterCloseMax, setDueDaysAfterCloseMax] = useState<
    number | null
  >(null);
  const [
    selectedJournalEntrySelectionType,
    setSelectedJournalEntrySelectionType,
  ] = useState<JournalEntrySelectionType | 'Skipped' | null>();
  const [journalEntryCounts, setJournalEntryCounts] =
    useState<JournalEntryCounts | null>();
  const [skippedJournalEntryCount, setSkippedJournalEntryCount] = useState<
    number | null
  >(null);

  const [alertModal, setAlertModal] = useState<any>();
  const { error, warning, apolloError } = useModalAlerts(setAlertModal);

  const handlePeriodFilterChanged = (period?: LookupModel) => {
    clearSearchResults();
    setSelectedPeriodId(period?.id);
    setFirstPeriodSelectionHasOccurred(true);
  };

  const handlePeriodStartFilterChanged = (period?: LookupModel) => {
    clearSearchResults();
    setSelectedPeriodStartId(period?.id);
  };

  const handlePeriodEndFilterChanged = (period?: LookupModel) => {
    clearSearchResults();
    setSelectedPeriodEndId(period?.id);
  };

  const handleResponsibleUserFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    clearSearchResults();
    setSelectedResponsibleUserIds(options.map((o) => parseInt(o.id)));
  };

  const handleEntityFilterChanged = (options: readonly IChipOption[]) => {
    clearSearchResults();
    setSelectedEntityIds(options.map((o) => parseInt(o.id)));
  };

  const handleGroupFilterChanged = (options: readonly IChipOption[]) => {
    clearSearchResults();
    setSelectedGroupIds(options.map((o) => parseInt(o.id)));
  };

  const handleCompletedByUserFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    clearSearchResults();
    setSelectedCompletedByNames(options.map((o) => o.id));
  };

  const handlePeriodSelectionTypeChanged = (
    periodSelectionType: PeriodSelectionType,
  ) => {
    clearSearchResults();
    setPeriodSelectionType(periodSelectionType);
  };

  const handleDueDaysAfterCloseMinChanged = (value: number | null) => {
    setDueDaysAfterCloseMin(value);
  };

  const handleDueDaysAfterCloseMaxChanged = (value: number | null) => {
    setDueDaysAfterCloseMax(value);
  };

  const handleClearSearchClick = () => {
    setPeriodSelectionType(PeriodSelectionType.period);
    setSelectedPeriodId(null);
    setSelectedPeriodStartId(null);
    setSelectedPeriodEndId(null);
    setSelectedResponsibleUserIds([]);
    setSelectedEntityIds([]);
    setSelectedGroupIds([]);
    setSelectedCompletedByNames([]);
    setDueDaysAfterCloseMin(null);
    setDueDaysAfterCloseMax(null);
    setSelectedJournalEntrySelectionType(null);
    clearSearchResults();
  };

  const handleSearchClick = async (): Promise<void> => {
    if (
      periodSelectionType === PeriodSelectionType.periodRange &&
      selectedPeriodStartId == null &&
      selectedPeriodEndId == null
    ) {
      error({
        message: 'Please choose a starting period, ending period or both.',
      });
      return;
    }

    if (
      dueDaysAfterCloseMin !== null &&
      dueDaysAfterCloseMax !== null &&
      dueDaysAfterCloseMin > dueDaysAfterCloseMax
    ) {
      error({
        message:
          'The Number of Days After Close "from" must be less than or the same as "to"',
      });
      return;
    }

    await clearSearchResults();

    await fetchCounts()
      .then(async (counts) => {
        setJournalEntryCounts(counts.data.journalEntryCounts);

        await fetchSkippedCount()
          .then((counts) => {
            setSkippedJournalEntryCount(counts.data.skippedJournalEntryCount);
          })
          .catch((reason) => {
            clearSearchResults();
            setSelectedJournalEntrySelectionType(null);
            apolloError({
              error: reason,
            });
          });
      })
      .catch(async (reason) => {
        await clearSearchResults();
        setSelectedJournalEntrySelectionType(null);
        apolloError({
          error: reason,
        });
      });
  };

  useEffect(() => {
    if (firstPeriodSelectionHasOccurred) {
      handleSearchClick();
    }
  }, [firstPeriodSelectionHasOccurred]);

  const clearSearchResults = async (): Promise<void> => {
    setJournalEntryCounts(null);
    setSkippedJournalEntryCount(null);
    await props.onSearch({});
  };

  const handleJournalEntrySelectionTypeChanged = async (
    selectionType: JournalEntrySelectionType | 'Skipped',
  ): Promise<void> => {
    await props.onSearch({ ...getFilterValues(), selectionType });
  };

  const fetchCounts = async () => {
    return client.query<
      JournalEntryCountsQuery,
      JournalEntryCountsQueryVariables
    >({
      query: JournalEntryCountsDocument,
      variables: {
        filter: {
          ...getFilterValues(),
          selectionType: null,
        } as JournalEntryFilter,
      },
      fetchPolicy: 'no-cache',
    });
  };

  const fetchSkippedCount = async () => {
    return client.query<
      SkippedJournalEntryCountQuery,
      SkippedJournalEntryCountQueryVariables
    >({
      query: SkippedJournalEntryCountDocument,
      variables: {
        filter: {
          ...getFilterValues(),
          selectionType: JournalEntrySelectionType.All,
        } as JournalEntryFilter,
      },
      fetchPolicy: 'no-cache',
    });
  };

  const getFilterValues = (): IJournalEntryFilterValues => {
    return {
      periodId:
        periodSelectionType === PeriodSelectionType.period && selectedPeriodId
          ? selectedPeriodId
          : undefined,
      periodStartId:
        periodSelectionType === PeriodSelectionType.periodRange &&
        selectedPeriodStartId
          ? selectedPeriodStartId
          : undefined,
      periodEndId:
        periodSelectionType === PeriodSelectionType.periodRange &&
        selectedPeriodEndId
          ? selectedPeriodEndId
          : undefined,
      kbsEntityLookupIdIn: selectedEntityIds.length
        ? selectedEntityIds
        : undefined,
      kbsGroupLookupIdIn: selectedGroupIds.length
        ? selectedGroupIds
        : undefined,
      responsibleUserIdIn: selectedResponsibleUserIds.length
        ? selectedResponsibleUserIds
        : undefined,
      completedByNameIn: selectedCompletedByNames.length
        ? selectedCompletedByNames
        : undefined,
      selectionType:
        selectedJournalEntrySelectionType ?? JournalEntrySelectionType.All,
      dueDaysAfterCloseMin: dueDaysAfterCloseMin ?? undefined,
      dueDaysAfterCloseMax: dueDaysAfterCloseMax ?? undefined,
    };
  };

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="Period"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.period}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(PeriodSelectionType.period);
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodFilterChanged}
                selectedId={selectedPeriodId}
                disabled={periodSelectionType !== PeriodSelectionType.period}
                selectFirst={true}
              />
            </Column>

            <Column></Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="Period Range"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.periodRange}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.periodRange,
                  );
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodStartFilterChanged}
                selectedId={selectedPeriodStartId}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
            -
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodEndFilterChanged}
                selectedId={selectedPeriodEndId}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="All Periods"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.allPeriods}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.allPeriods,
                  );
                }}
              />
            </FormLabelColumn>
            <Column></Column>
            <Column></Column>
          </div>
        </FieldSetColumn>
        <FieldSetColumn>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Entity</FormLabelColumn>
            <Column>
              <KbsEntityChipSelect
                selectedIds={selectedEntityIds}
                onChange={handleEntityFilterChanged}
                placeholder="All Entities"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Group</FormLabelColumn>
            <Column>
              <KbsGroupChipSelect
                selectedIds={selectedGroupIds}
                onChange={handleGroupFilterChanged}
                placeholder="All Groups"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Responsible</FormLabelColumn>
            <Column>
              <UserChipSelect
                selectedIds={selectedResponsibleUserIds}
                onChange={handleResponsibleUserFilterChanged}
                placeholder="All Responsible"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>CompletedBy</FormLabelColumn>
            <Column>
              <CompletedByChipSelect
                periodId={
                  periodSelectionType === PeriodSelectionType.period &&
                  selectedPeriodId
                    ? selectedPeriodId
                    : undefined
                }
                periodStartId={
                  periodSelectionType === PeriodSelectionType.periodRange &&
                  selectedPeriodStartId
                    ? selectedPeriodStartId
                    : undefined
                }
                periodEndId={
                  periodSelectionType === PeriodSelectionType.periodRange &&
                  selectedPeriodEndId
                    ? selectedPeriodEndId
                    : undefined
                }
                selectedIds={selectedCompletedByNames}
                onChange={handleCompletedByUserFilterChanged}
                placeholder="All Completed By Users"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Number of Days After Close <DueDaysAfterCloseInfo />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width="auto">
                  <PlainText>From</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMin}
                    onChange={handleDueDaysAfterCloseMinChanged}
                    minimumValue={0}
                  />
                </Column>
                <Column width={2} className="text-center">
                  <PlainText>to</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMax}
                    onChange={handleDueDaysAfterCloseMaxChanged}
                    minimumValue={0}
                  />
                </Column>
              </div>
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
      <div className="row">
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Journal Entry Status</h1>
            </Column>
          </SectionHeaderRow>
          <hr />
          <Column>
            <div className="row">
              <Column width={2}>
                <ButtonGroup toggle className="w-100" size="lg">
                  <ToggleButton
                    type="checkbox"
                    variant="secondary"
                    value="1"
                    checked={
                      selectedJournalEntrySelectionType ===
                      JournalEntrySelectionType.Incomplete
                    }
                    onClick={() =>
                      handleJournalEntrySelectionTypeChanged(
                        JournalEntrySelectionType.Incomplete,
                      )
                    }
                  >
                    <div className="row">
                      <Column width={7} className="text-left">
                        Incomplete
                      </Column>
                      <Column width={5} className="text-right">
                        {journalEntryCounts?.incompleteItems}
                      </Column>
                    </div>
                  </ToggleButton>
                </ButtonGroup>
              </Column>
              <Column width={2}>
                <ButtonGroup toggle className="w-100" size="lg">
                  <ToggleButton
                    type="checkbox"
                    variant="secondary"
                    value="1"
                    checked={
                      selectedJournalEntrySelectionType ===
                      JournalEntrySelectionType.Completed
                    }
                    onClick={() =>
                      handleJournalEntrySelectionTypeChanged(
                        JournalEntrySelectionType.Completed,
                      )
                    }
                  >
                    <div className="row">
                      <Column width={7} className="text-left">
                        Completed
                      </Column>
                      <Column width={5} className="text-right">
                        {journalEntryCounts?.completedItems}
                      </Column>
                    </div>
                  </ToggleButton>
                </ButtonGroup>
              </Column>
              <Column width={2}>
                <ButtonGroup toggle className="w-100" size="lg">
                  <ToggleButton
                    type="checkbox"
                    variant="secondary"
                    value="1"
                    checked={
                      selectedJournalEntrySelectionType ===
                      JournalEntrySelectionType.All
                    }
                    onClick={() =>
                      handleJournalEntrySelectionTypeChanged(
                        JournalEntrySelectionType.All,
                      )
                    }
                  >
                    <div className="row">
                      <Column width={7} className="text-left">
                        All
                      </Column>
                      <Column width={5} className="text-right">
                        {journalEntryCounts?.totalItems}
                      </Column>
                    </div>
                  </ToggleButton>
                </ButtonGroup>
              </Column>
              <Column width={4} />
              <Column width={2}>
                <ButtonGroup toggle className="w-100" size="lg">
                  <ToggleButton
                    type="checkbox"
                    variant="secondary"
                    value="1"
                    checked={selectedJournalEntrySelectionType === 'Skipped'}
                    onClick={() =>
                      handleJournalEntrySelectionTypeChanged('Skipped')
                    }
                  >
                    <div className="row">
                      <Column width={7} className="text-left">
                        Skipped
                      </Column>
                      <Column width={5} className="text-right">
                        {skippedJournalEntryCount}
                      </Column>
                    </div>
                  </ToggleButton>
                </ButtonGroup>
              </Column>
            </div>
          </Column>
        </Column>
      </div>
    </>
  );
};
