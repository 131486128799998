import { ChipSelect, IChipOption } from 'components/ChipSelect';
import { LookupWrapper } from 'components/Lookups/LookupComponentWrapper';
import { DocumentNode } from 'graphql';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps<TQuery, TQueryVariables> {
  options?: IChipOption[];
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  queryDocument: DocumentNode;
  queryVariables: TQueryVariables;
  extractLookups: (query: TQuery) => LookupModel[];
  placeholder?: string;
}

export const LookupChipSelect = <TQuery, TQueryVariables>(
  props: IProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const toChipOption = (lookup: LookupModel): IChipOption => {
    return {
      id: lookup.id.toString(),
      label: lookup.name,
    };
  };

  return (
    <LookupWrapper<TQuery, TQueryVariables>
      queryDocument={props.queryDocument}
      queryVariables={props.queryVariables}
      selectedValues={props.selectedIds}
      extractLookups={props.extractLookups}
    >
      {({ lookups, selectedLookups }) => (
        <ChipSelect
          availableOptions={lookups.map(toChipOption)}
          selectedOptions={selectedLookups.map(toChipOption)}
          onSelect={props.onChange}
          placeholder={props.placeholder}
        />
      )}
    </LookupWrapper>
  );
};
