import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MonthlyChecklistItemActionEnum } from 'types/graphql';
import React, { useState } from 'react';
import { Dropdown, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { Button } from '../../Button';
import SectionHeaderRow from '../../../layouts/components/Grid/SectionHeaderRow';
import Column from '../../../layouts/components/Grid/Column';
import TextAreaInput from '../../Form/TextAreaInput';

export interface ISelectProps {
  value?: MonthlyChecklistItemActionEnum;
  existingComment?: string | undefined;
  onSelect: (
    value?: MonthlyChecklistItemActionEnum,
    comment?: string | undefined,
  ) => void;
  disabled?: boolean;
}

const CustomToggle = React.forwardRef(
  (
    {
      children,
      onClick,
      disabled,
      allowNewItems,
      onInputChange,
      onInputBlur,
    }: any,
    ref,
  ) => {
    if (allowNewItems) {
      return (
        <div
          ref={ref as any}
          onClick={
            disabled
              ? undefined
              : (e: any) => {
                  e.preventDefault();
                  onClick(e);
                }
          }
        >
          <InputGroup className={`seamless ${disabled ? 'disabled' : ''}`}>
            <FormControl
              value={children}
              onChange={onInputChange}
              onBlur={onInputBlur}
              disabled={disabled}
            ></FormControl>
            <InputGroup.Append>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faChevronDown} />
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </div>
      );
    }

    return (
      <div
        className={`form-control select-dropdown-form-control ${
          disabled ? 'text-muted' : ''
        }`}
        style={{ cursor: disabled ? 'unset' : 'pointer' }}
        ref={ref as any}
        onClick={
          disabled
            ? undefined
            : (e) => {
                e.preventDefault();
                onClick(e);
              }
        }
      >
        <div className="row no-gutters">
          <div className="col-11">{children}</div>
          <div className="col-1 text-right">
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        </div>
      </div>
    );
  },
);

export const MonthlyChecklistItemActionSelect = (
  props: ISelectProps,
): JSX.Element => {
  const [showCommentModal, setShowCommentModal] = useState<boolean>(false);
  const [comment, setComment] = useState<string | undefined>(
    props.existingComment,
  );

  const handleSelect = (value: string | null) => {
    const selectedItem = value as MonthlyChecklistItemActionEnum;
    if (selectedItem === MonthlyChecklistItemActionEnum.Skip) {
      setShowCommentModal(true);
      return;
    }
    props.onSelect(selectedItem, props.existingComment);
  };

  const handleCommentCancel = (): void => {
    setComment(props.existingComment);
    setShowCommentModal(false);
  };

  const handleCommentSupplied = (): void => {
    setShowCommentModal(false);
    props.onSelect(MonthlyChecklistItemActionEnum.Skip, comment);
  };

  return (
    <>
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle as={CustomToggle} disabled={props.disabled}>
          {props.value ?? 'Select...'}
        </Dropdown.Toggle>

        <Dropdown.Menu className="select-dropdown-menu">
          <Dropdown.Item eventKey={MonthlyChecklistItemActionEnum.Include}>
            {MonthlyChecklistItemActionEnum.Include}
          </Dropdown.Item>

          <Dropdown.Item eventKey={MonthlyChecklistItemActionEnum.Skip}>
            {MonthlyChecklistItemActionEnum.Skip}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {showCommentModal && (
        <Modal show={true} backdrop="static" centered={true} keyboard={false}>
          <Modal.Body>
            <SectionHeaderRow>
              <Column>
                <h2>Please supply a comment for this Skipped item</h2>
              </Column>
            </SectionHeaderRow>
            <hr className="mt-0" />
            <TextAreaInput
              rows={10}
              value={comment || ''}
              onChange={setComment}
              className="outlined no-resize"
            ></TextAreaInput>
            <div className="row mt-3">
              <Column className="text-center">
                <Button
                  variant="primary"
                  className="mr-3"
                  disabled={!(comment?.trim() || '')}
                  onClick={handleCommentSupplied}
                >
                  Save
                </Button>
                <Button variant="warning" onClick={handleCommentCancel}>
                  Cancel
                </Button>
              </Column>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
