import Column from 'layouts/components/Grid/Column';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import { Page } from 'layouts/Page';
import React, { useRef, useState } from 'react';
import StatusReportTable, {
  IJournalEntryTableControl,
} from './components/StatusReportTable';
import {
  IJournalEntryFilterValues,
  StatusReportFilters,
} from './components/StatusReportFilters';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';

export const StatusReport = (): JSX.Element => {
  const tableControl = useRef<IJournalEntryTableControl>();
  const [filterValues, setFilterValues] = useState<IJournalEntryFilterValues>(
    {},
  );

  const handleSearch = async (values: IJournalEntryFilterValues) => {
    setFilterValues(values);
    await tableControl.current?.filter(values);
  };

  const handleExport = async () => {
    return tableControl.current?.export({ filename: 'journal-entries.xlsx' });
  };

  return (
    <>
      <Page title="Status Report">
        <SectionHeaderRow>
          <Column>
            <h1>Filters</h1>
          </Column>
        </SectionHeaderRow>
        <hr />

        <StatusReportFilters
          onSearch={handleSearch}
          filterValues={filterValues}
        ></StatusReportFilters>
        {Object.keys(filterValues).length > 0 && (
          <>
            <SectionHeaderRow className="mt-5">
              <Column>
                <h1>
                  Journal Entries Details for:{' '}
                  {filterValues.selectionType?.toString()}
                </h1>
              </Column>
            </SectionHeaderRow>
            <hr />
            <div className="row">
              <Column className="text-left">
                <ExportToExcelButton onClick={handleExport} />
              </Column>
            </div>
          </>
        )}
        <StatusReportTable controlRef={tableControl} />
      </Page>
    </>
  );
};
