import { DocumentNode } from 'graphql';
import React from 'react';
import {
  KbsGroupLookupsDocument,
  KbsGroupLookupsQuery,
  KbsGroupLookupsQueryVariables,
  LookupModel,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: KbsGroupLookupsQueryVariables;
    extractLookups: (query: KbsGroupLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const KbsGroupLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: KbsGroupLookupsDocument,
        queryVariables: { includeDeleted: props.includeDeleted },
        extractLookups: (query) => query.kbsGroups,
      })}
    </>
  );
};
