import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

export interface IExportToExcelButtonProps {
  onClick: () => Promise<void>;
}

export const ExportToExcelButton = (
  props: IExportToExcelButtonProps,
): JSX.Element => {
  const [downloading, setDownloading] = useState(false);

  const handleClick = () => {
    setDownloading(true);
    props.onClick().finally(() => {
      setDownloading(false);
    });
  };

  return (
    <Button
      size="sm"
      variant="link"
      disabled={downloading}
      onClick={handleClick}
    >
      {!downloading && (
        <>
          <FontAwesomeIcon icon={faFileExcel} className="mr-2" />
          Export to Excel
        </>
      )}

      {downloading && (
        <>
          <FontAwesomeIcon icon={faSpinner} className="mr-2 fa-spin" />
          Downloading
        </>
      )}
    </Button>
  );
};
