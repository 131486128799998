import { DocumentNode } from 'graphql';
import React from 'react';
import {
  CompletedByLookupsDocument,
  CompletedByLookupsQuery,
  CompletedByLookupsQueryVariables,
  StringLookupModel,
} from 'types/graphql';

interface IProps {
  periodId?: number | null;
  periodStartId?: number | null;
  periodEndId?: number | null;
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: CompletedByLookupsQueryVariables;
    extractStringLookups: (
      query: CompletedByLookupsQuery,
    ) => StringLookupModel[];
  }) => React.ReactNode;
}

export const CompletedByLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: CompletedByLookupsDocument,
        queryVariables: {
          periodId: props.periodId,
          periodStartId: props.periodStartId,
          periodEndId: props.periodEndId,
          includeDeleted: props.includeDeleted,
        },
        extractStringLookups: (query) => query.completedByLookups,
      })}
    </>
  );
};
