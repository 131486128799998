import { IChipOption } from 'components/ChipSelect';
import { CompletedByLookupWrapper } from 'components/Lookups/CompletedByLookups/CompletedByLookupWrapper';
import React from 'react';
import { StringLookupChipSelect } from '../StringLookupChipSelect';

interface IProps {
  periodId?: number | null;
  periodStartId?: number | null;
  periodEndId?: number | null;
  includeDeleted?: boolean;
  selectedIds?: string[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
}

export const CompletedByChipSelect = (props: IProps): JSX.Element => {
  return (
    <CompletedByLookupWrapper
      periodId={props.periodId}
      periodStartId={props.periodStartId}
      periodEndId={props.periodEndId}
      includeDeleted={props.includeDeleted}
    >
      {({ queryDocument, queryVariables, extractStringLookups }) => (
        <StringLookupChipSelect
          extractStringLookups={extractStringLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          onChange={props.onChange}
          selectedIds={props.selectedIds}
          placeholder={props.placeholder}
        />
      )}
    </CompletedByLookupWrapper>
  );
};
