import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { PropsWithChildren } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ButtonVariant } from 'react-bootstrap/esm/types';

export interface IAlertModalProps extends PropsWithChildren<unknown> {
  title: string;
  icon?: IconProp;
  label?: string | null;
  body?: JSX.Element | string;
  variant?: 'danger' | 'warning' | 'success' | 'primary';
  size?: 'sm' | 'lg' | 'xl';
  scrollable?: boolean;
  verticallyCentered?: boolean;
  okButtonText?: string;
  onOkClick?: () => void;
  okButtonVariant?: ButtonVariant;
  okButtonEnabled?: boolean;
  cancelButtonText?: string;
  onCancelClick?: () => void;
  cancelButtonVariant?: ButtonVariant;
  cancelButtonEnabled?: boolean;
  onCloseClick?: () => void;
  show?: boolean;
}

export const AlertModal = (props: IAlertModalProps): JSX.Element => {
  const className = props.variant
    ? `modal-alert modal-${props.variant}`
    : undefined;

  return (
    <Modal
      show={props.show ?? true}
      backdrop={'static'}
      centered={props.verticallyCentered}
      keyboard={false}
      scrollable={props.scrollable}
      className={className}
    >
      <Modal.Header>
        <Modal.Title>
          <div className="d-flex">
            {props.icon && (
              <div className="modal-icon-wrapper">
                <FontAwesomeIcon icon={props.icon} />
              </div>
            )}
            <div className="modal-title-text text-uppercase">{props.title}</div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.label && <label>{props.label}</label>}
        {props.body && (
          <>
            {typeof props.body === 'string' ? (
              <p>{props.body}</p>
            ) : (
              <div>{props.body}</div>
            )}
          </>
        )}
        {props.children && <>{props.children}</>}
      </Modal.Body>
      <Modal.Footer>
        {!!props.onCancelClick && (
          <Button
            variant={props.cancelButtonVariant ?? 'secondary'}
            size="sm"
            onClick={props.onCancelClick}
            disabled={!(props.cancelButtonEnabled ?? true)}
          >
            Cancel
          </Button>
        )}
        {!!props.onOkClick && (
          <Button
            variant={props.okButtonVariant ?? props.variant ?? 'primary'}
            size="sm"
            onClick={props.onOkClick}
            disabled={
              props.okButtonEnabled !== undefined
                ? !props.okButtonEnabled
                : false
            }
          >
            OK
          </Button>
        )}

        {!!props.onCloseClick && (
          <Button
            variant={'link'}
            size="sm"
            onClick={props.onCloseClick}
            className="d-flex align-items-center"
          >
            <span>Close window</span> <FontAwesomeIcon icon={faTimes} />
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};
