import { Page } from 'layouts/Page';
import React from 'react';
import { BulkUpdateDetail } from './components/BulkUpdateDetail';

export const BulkUpdate = (): JSX.Element => {
  return (
    <Page title="Bulk Update">
      <BulkUpdateDetail />
    </Page>
  );
};
