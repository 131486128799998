import { ChipAutosuggest } from 'components/ChipAutosuggest';
import { IChipOption } from 'components/ChipSelect';
import { DocumentNode } from 'graphql';
import React from 'react';
import useApolloClient from 'useApolloClient';

interface IProps<TQuery, TQueryVariables> {
  options?: (inputValue?: string) => Promise<IChipOption[]>;
  selectedOptions?: IChipOption[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  queryDocument: DocumentNode;
  queryVariables: (inputValue?: string) => TQueryVariables;
  extractChipOptions: (query: TQuery) => IChipOption[];
}

export const LookupChipAutosuggest = <TQuery, TQueryVariables>(
  props: IProps<TQuery, TQueryVariables>,
): JSX.Element => {
  const { client } = useApolloClient();

  const loadOptions = async (inputValue?: string) => {
    const result = await client.query<TQuery, TQueryVariables>({
      query: props.queryDocument,
      variables: props.queryVariables(inputValue),
      fetchPolicy: 'no-cache',
    });

    return props.extractChipOptions(result.data);
  };

  return (
    <ChipAutosuggest
      availableOptions={loadOptions}
      selectedOptions={props.selectedOptions ?? []}
      onSelect={props.onChange}
    />
  );
};
