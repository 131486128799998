import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IProps {
  onClick?: () => void;
}

export const RemoveIcon = (props: IProps): JSX.Element => {
  return (
    <FontAwesomeIcon
      icon={faMinusCircle}
      className="text-danger pointer"
      title="Remove"
      onClick={props.onClick}
    />
  );
};
