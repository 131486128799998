import React from 'react';

interface IProps {
  title?: string | null;
}

export const PageHeader = (props: IProps): JSX.Element => {
  return (
    <>
      <div className="page-header container-fluid  pl-4 pr-4">
        <div className="row">
          <div className="col">
            <span className="page-title">{props.title}</span>
          </div>
          <div className="col text-right">
            <span className="app-title">Journal Entry Checklist System</span>
            <div className={`header-logo`}></div>
          </div>
        </div>
      </div>
    </>
  );
};
