import useModalAlerts from 'components/Alerts/useModalAlerts';
import { IChipOption } from 'components/ChipSelect';
import { CheckBlock } from 'components/Form/CheckBlock';
import { CompletedByChipSelect } from 'components/Lookups/CompletedByLookups/CompletedByChipSelect';
import { KbsEntityChipSelect } from 'components/Lookups/KbsEntityLookups/KbsEntityChipSelect';
import { KbsGroupChipSelect } from 'components/Lookups/KbsGroupLookups/KbsGroupChipSelect';
import { PeriodEntitySelect } from 'components/Lookups/PeriodLookups/PeriodEntitySelect';
import { UserChipSelect } from 'components/Lookups/UserLookups/UserChipSelect';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { JournalEntrySelectionType, LookupModel } from 'types/graphql';
import { DueDaysAfterCloseInfo } from 'components/DueDaysAfterCloseInfo/DueDaysAfterCloseInfo';
import { IntegerInput } from 'components/Form/IntegerInput';
import { PlainText } from 'components/Form/PlainText';

export interface IJournalEntryFilterValues {
  periodId?: number;
  periodStartId?: number;
  periodEndId?: number;
  responsibleUserIdIn?: number[];
  kbsEntityLookupIdIn?: number[];
  kbsGroupLookupIdIn?: number[];
  completedByNameIn?: string[];
  selectionType?: JournalEntrySelectionType;
  dueDaysAfterCloseMin?: number;
  dueDaysAfterCloseMax?: number;
}

export enum PeriodSelectionType {
  period = 1,
  periodRange = 2,
  allPeriods = 3,
  none = 4,
}

interface IProps {
  filterValues?: IJournalEntryFilterValues;
  onSearch: (filterValues: IJournalEntryFilterValues) => Promise<void>;
}

export const ReviewJournalEntryFilters: React.FunctionComponent<IProps> = (
  props: IProps,
) => {
  const [firstPeriodSelectionHasOccurred, setFirstPeriodSelectionHasOccurred] =
    useState<boolean>(false);

  const [isSearching, setIsSearching] = useState(false);

  const [periodSelectionType, setPeriodSelectionType] =
    useState<PeriodSelectionType>(PeriodSelectionType.period);
  const [selectedPeriodId, setSelectedPeriodId] = useState<number | null>();
  const [selectedPeriodStartId, setSelectedPeriodStartId] = useState<
    number | null
  >();
  const [selectedPeriodEndId, setSelectedPeriodEndId] = useState<
    number | null
  >();
  const [selectedResponsibleUserIds, setSelectedResponsibleUserIds] = useState<
    number[]
  >([]);
  const [selectedEntityIds, setSelectedEntityIds] = useState<number[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[]>([]);
  const [selectedCompletedByNames, setSelectedCompletedByNames] = useState<
    string[]
  >([]);
  const [dueDaysAfterCloseMin, setDueDaysAfterCloseMin] = useState<
    number | null
  >(null);
  const [dueDaysAfterCloseMax, setDueDaysAfterCloseMax] = useState<
    number | null
  >(null);
  const [selectedIncompleteOnlyFlag, setIncompleteOnlyFlag] =
    useState<boolean>(false);

  const [alertModal, setAlertModal] = useState<any>();
  const { error } = useModalAlerts(setAlertModal);

  const handlePeriodFilterChanged = (period?: LookupModel) => {
    setSelectedPeriodId(period?.id);
    setFirstPeriodSelectionHasOccurred(true);
  };

  const handlePeriodStartFilterChanged = (period?: LookupModel) => {
    setSelectedPeriodStartId(period?.id);
  };

  const handlePeriodEndFilterChanged = (period?: LookupModel) => {
    setSelectedPeriodEndId(period?.id);
  };

  const handleResponsibleUserFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedResponsibleUserIds(options.map((o) => parseInt(o.id)));
  };

  const handleEntityFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedEntityIds(options.map((o) => parseInt(o.id)));
  };

  const handleGroupFilterChanged = (options: readonly IChipOption[]) => {
    setSelectedGroupIds(options.map((o) => parseInt(o.id)));
  };

  const handleCompletedByUserFilterChanged = (
    options: readonly IChipOption[],
  ) => {
    setSelectedCompletedByNames(options.map((o) => o.id));
  };

  const handleIncompleteOnlyFlagFilterChanged = () => {
    setIncompleteOnlyFlag(!selectedIncompleteOnlyFlag);
  };

  const handlePeriodSelectionTypeChanged = (
    periodSelectionType: PeriodSelectionType,
  ) => {
    setPeriodSelectionType(periodSelectionType);
  };

  const handleDueDaysAfterCloseMinChanged = (value: number | null) => {
    setDueDaysAfterCloseMin(value);
  };

  const handleDueDaysAfterCloseMaxChanged = (value: number | null) => {
    setDueDaysAfterCloseMax(value);
  };

  const handleClearSearchClick = () => {
    setPeriodSelectionType(PeriodSelectionType.period);
    setSelectedPeriodId(null);
    setSelectedPeriodStartId(null);
    setSelectedPeriodEndId(null);
    setSelectedResponsibleUserIds([]);
    setSelectedEntityIds([]);
    setSelectedGroupIds([]);
    setSelectedCompletedByNames([]);
    setDueDaysAfterCloseMin(null);
    setDueDaysAfterCloseMax(null);
    setIncompleteOnlyFlag(false);
    props.onSearch({});
  };

  const handleSearchClick = () => {
    if (
      periodSelectionType === PeriodSelectionType.periodRange &&
      selectedPeriodStartId == null &&
      selectedPeriodEndId == null
    ) {
      error({
        message: 'Please choose a starting period, ending period or both.',
      });
      return;
    }

    if (
      dueDaysAfterCloseMin !== null &&
      dueDaysAfterCloseMax !== null &&
      dueDaysAfterCloseMin > dueDaysAfterCloseMax
    ) {
      error({
        message:
          'The Number of Days After Close "from" must be less than or the same as "to"',
      });
      return;
    }

    search();
  };

  const search = () => {
    setIsSearching(true);
    props
      .onSearch({
        periodId:
          periodSelectionType === PeriodSelectionType.period && selectedPeriodId
            ? selectedPeriodId
            : undefined,
        periodStartId:
          periodSelectionType === PeriodSelectionType.periodRange &&
          selectedPeriodStartId
            ? selectedPeriodStartId
            : undefined,
        periodEndId:
          periodSelectionType === PeriodSelectionType.periodRange &&
          selectedPeriodEndId
            ? selectedPeriodEndId
            : undefined,
        kbsEntityLookupIdIn: selectedEntityIds.length
          ? selectedEntityIds
          : undefined,
        kbsGroupLookupIdIn: selectedGroupIds.length
          ? selectedGroupIds
          : undefined,
        responsibleUserIdIn: selectedResponsibleUserIds.length
          ? selectedResponsibleUserIds
          : undefined,
        completedByNameIn: selectedCompletedByNames.length
          ? selectedCompletedByNames
          : undefined,
        dueDaysAfterCloseMin: dueDaysAfterCloseMin ?? undefined,
        dueDaysAfterCloseMax: dueDaysAfterCloseMax ?? undefined,
        selectionType: selectedIncompleteOnlyFlag
          ? JournalEntrySelectionType.Incomplete
          : JournalEntrySelectionType.All,
      })
      .finally(() => {
        setIsSearching(false);
      });
  };

  useEffect(() => {
    if (firstPeriodSelectionHasOccurred) {
      search();
    }
  }, [firstPeriodSelectionHasOccurred]);

  return (
    <>
      {alertModal}
      <div className="row">
        <FieldSetColumn>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="Period"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.period}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(PeriodSelectionType.period);
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodFilterChanged}
                selectedId={selectedPeriodId}
                disabled={periodSelectionType !== PeriodSelectionType.period}
                selectFirst={true}
              />
            </Column>

            <Column></Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="Period Range"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.periodRange}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.periodRange,
                  );
                }}
              />
            </FormLabelColumn>
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodStartFilterChanged}
                selectedId={selectedPeriodStartId}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
            -
            <Column>
              <PeriodEntitySelect
                onSelect={handlePeriodEndFilterChanged}
                selectedId={selectedPeriodEndId}
                disabled={
                  periodSelectionType !== PeriodSelectionType.periodRange
                }
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              <Form.Check
                type="radio"
                label="All Periods"
                className="mt-1"
                checked={periodSelectionType == PeriodSelectionType.allPeriods}
                onChange={() => {
                  handlePeriodSelectionTypeChanged(
                    PeriodSelectionType.allPeriods,
                  );
                }}
              />
            </FormLabelColumn>
            <Column></Column>
            <Column></Column>
          </div>
        </FieldSetColumn>
        <FieldSetColumn>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Entity</FormLabelColumn>
            <Column>
              <KbsEntityChipSelect
                selectedIds={selectedEntityIds}
                onChange={handleEntityFilterChanged}
                placeholder="All Entities"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Group</FormLabelColumn>
            <Column>
              <KbsGroupChipSelect
                selectedIds={selectedGroupIds}
                onChange={handleGroupFilterChanged}
                placeholder="All Groups"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Responsible</FormLabelColumn>
            <Column>
              <UserChipSelect
                selectedIds={selectedResponsibleUserIds}
                onChange={handleResponsibleUserFilterChanged}
                placeholder="All Responsible"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Completed By</FormLabelColumn>
            <Column>
              <CompletedByChipSelect
                periodId={
                  periodSelectionType === PeriodSelectionType.period &&
                  selectedPeriodId
                    ? selectedPeriodId
                    : undefined
                }
                periodStartId={
                  periodSelectionType === PeriodSelectionType.periodRange &&
                  selectedPeriodStartId
                    ? selectedPeriodStartId
                    : undefined
                }
                periodEndId={
                  periodSelectionType === PeriodSelectionType.periodRange &&
                  selectedPeriodEndId
                    ? selectedPeriodEndId
                    : undefined
                }
                selectedIds={selectedCompletedByNames}
                onChange={handleCompletedByUserFilterChanged}
                placeholder="All Completed By Users"
              />
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>
              Number of Days After Close <DueDaysAfterCloseInfo />
            </FormLabelColumn>
            <Column>
              <div className="row">
                <Column width="auto">
                  <PlainText>From</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMin}
                    onChange={handleDueDaysAfterCloseMinChanged}
                    minimumValue={0}
                  />
                </Column>
                <Column width={2} className="text-center">
                  <PlainText>to</PlainText>
                </Column>
                <Column>
                  <IntegerInput
                    value={dueDaysAfterCloseMax}
                    onChange={handleDueDaysAfterCloseMaxChanged}
                    minimumValue={0}
                  />
                </Column>
              </div>
            </Column>
          </div>
          <div className="row mt-3">
            <FormLabelColumn width={5}>Incomplete Only</FormLabelColumn>
            <Column>
              <CheckBlock
                checked={selectedIncompleteOnlyFlag}
                onCheckedChange={handleIncompleteOnlyFlagFilterChanged}
              />
            </Column>
          </div>
        </FieldSetColumn>
      </div>
      <div className="row">
        <Column className="text-right mt-3">
          <Button
            variant="primary"
            size="lg"
            className="text-uppercase font-weight-bold mr-3"
            disabled={isSearching}
            onClick={handleSearchClick}
          >
            Search
          </Button>
          <Button
            variant="warning"
            size="lg"
            className="text-uppercase font-weight-bold"
            disabled={isSearching}
            onClick={handleClearSearchClick}
          >
            Clear
          </Button>
        </Column>
      </div>
    </>
  );
};
