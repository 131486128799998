import React from 'react';
import { Select } from './Select';

interface IProps {
  earliestYear?: number | undefined;
  value?: number | null;
  onSelect: (value?: number | null) => void;
}

export const YearSelect = (props: IProps): JSX.Element => {
  const currentYear = new Date().getFullYear();

  // Intent:  the starting year should either be last year, or an even earlier year
  let startingYear = currentYear - 1; // default to the current year minus one
  if (!!props.earliestYear && props.earliestYear < currentYear) {
    // If there's an earlier period year and it's prior to the current year
    // then use that earlier year instead.
    startingYear = props.earliestYear;
  }

  // In the odd event where the earliest year is further ahead than
  // next year, make the ending year the "earliest" year plus one
  let endingYear = currentYear + 1;
  if (!!props.earliestYear && props.earliestYear > endingYear) {
    endingYear = props.earliestYear + 1;
  }

  const yearNumbers: number[] = [];
  for (let year = startingYear; year <= endingYear; year++) {
    yearNumbers.push(year);
  }

  const items = [
    { id: null, label: '' },
    ...yearNumbers.map((n) => ({ id: n, label: n.toString() })),
  ];

  return (
    <Select
      items={items}
      selectedId={props.value}
      onSelect={(item) => {
        if (typeof item?.id === 'string') {
          return;
        }

        return props.onSelect(item?.id);
      }}
    />
  );
};
