import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Select, {
  ActionMeta,
  MenuPlacement,
  OptionsOrGroups,
  PropsValue,
} from 'react-select';

import AsyncSelect from 'react-select/async';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export interface ISwissArmySelectProps<OptionType> {
  className?: string;
  loadOptions?: (
    inputValue: string,
    callback: (options: OptionsOrGroups<OptionType, any>) => void,
  ) => void;
  defaultOptions?: OptionsOrGroups<OptionType, any>;
  getOptionLabel: (option: OptionType) => string;
  getOptionValue: (option: OptionType) => string;
  allowMultipleSelection?: boolean;
  placeholder?: string;
  menuPlacement?: MenuPlacement;
  value?: PropsValue<OptionType>;
  onChange?: (value?: OptionType | ReadonlyArray<OptionType> | null) => void;
  readonly?: boolean;
  isClearable?: boolean;
}

const DropdownIndicator = (): JSX.Element => {
  return <FontAwesomeIcon icon={faChevronDown} className="mr-1" />;
};

const customStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.menuColor,
    zIndex: 1000,
  }),
};
export default <OptionType,>(props: ISwissArmySelectProps<OptionType>) => {
  const handleChange = (
    value: PropsValue<OptionType>,
    action: ActionMeta<OptionType>,
  ): void => {
    let newValue: PropsValue<OptionType> | null | undefined;

    switch (action.action) {
      case 'select-option':
      case 'create-option':
        if (props.allowMultipleSelection && action.option) {
          const propsValue = props?.value || [];
          newValue = [...(propsValue as OptionType[]), action.option];
        } else {
          newValue = value;
        }
        break;
      case 'remove-value': // when the remove icon is clicked in multi-select
      case 'pop-value': // when backspace is pressed in multi-select
        const propsValue = (props.value || []) as OptionType[];
        const removedValue = action.removedValue;
        if (removedValue) {
          newValue = propsValue.filter(
            (val) =>
              props.getOptionValue(val) !== props.getOptionValue(removedValue),
          );
        } else {
          return;
        }
        break;
      case 'clear':
        newValue = props.allowMultipleSelection ? [] : null;
        break;
      default:
        console.warn(`Unhandled action ${action.action} encountered in Lookup`);

        return;
    }

    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const baseProps: StateManagerProps<OptionType> = {
    styles: customStyles,
    className: `swiss-army-select form-control p-0 flat ${
      props.readonly ? 'disabled' : ''
    }`,
    isMulti: props.allowMultipleSelection,
    placeholder: props.placeholder,
    menuPlacement: props.menuPlacement || 'auto',
    getOptionLabel: props.getOptionLabel,
    getOptionValue: props.getOptionValue,
    onChange: handleChange,
    value: props.value,
    isClearable: props.isClearable,
    isDisabled: props.readonly,
    components: { DropdownIndicator, IndicatorSeparator: null },
  };

  if (props.loadOptions) {
    return (
      <>
        <AsyncSelect
          {...baseProps}
          loadOptions={props.loadOptions}
          defaultOptions={props.defaultOptions}
        />
      </>
    );
  } else if (props.defaultOptions) {
    return (
      <>
        <Select {...baseProps} options={props.defaultOptions} />
      </>
    );
  }

  return <></>;
};
