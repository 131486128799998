import ApolloClient, { PresetConfig } from 'apollo-boost';
import auth from 'auth/AuthClient';
import { apiBaseUrl } from 'utils/site-util';

const useApolloClient = (
  config?: PresetConfig,
): {
  client: ApolloClient<any>;
} => {
  let headers = {};
  if (auth.getAccessToken()) {
    headers = {
      ...headers,
      Authorization: `Bearer ${auth.getAccessToken()}`,
    };
  }

  const client = new ApolloClient({
    uri: `${apiBaseUrl()}/graphql`,
    headers,
    /*
    onError: (error: ErrorResponse) => {
      if (isUnauthorizedErrorResponse(error)) {
        auth.signOut();
      }
    },
    */
    ...config,
  });

  return {
    client,
  };
};

export default useApolloClient;
