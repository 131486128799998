import { PeriodEntityLookupWrapper } from './PeriodLookupWrapper';
import { LookupSelect } from 'components/Lookups/LookupSelect';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
  disabled?: boolean | false;
  selectFirst?: boolean | false;
}

export const PeriodEntitySelect = (props: IProps): JSX.Element => {
  return (
    <PeriodEntityLookupWrapper includeDeleted={props.includeDeleted}>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={props.onSelect}
          disabled={props.disabled}
          selectFirst={props.selectFirst}
        />
      )}
    </PeriodEntityLookupWrapper>
  );
};
