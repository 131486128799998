import { LookupSelect } from 'components/Lookups/LookupSelect';
import { UserLookupWrapper } from 'components/Lookups/UserLookups/UserLookupWrapper';
import React from 'react';
import { LookupModel } from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  includeInactive?: boolean;
  selectedId?: number | null;
  onSelect: (item?: LookupModel) => void;
}

export const UserSelect = (props: IProps): JSX.Element => {
  return (
    <UserLookupWrapper
      includeDeleted={props.includeDeleted}
      includeInactive={props.includeInactive}
      includeId={props.selectedId ?? undefined}
    >
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupSelect
          extractLookupItems={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          selectedId={props.selectedId}
          onSelect={props.onSelect}
        />
      )}
    </UserLookupWrapper>
  );
};
