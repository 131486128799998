import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {
  faCommentAlt,
  faCommentAltLines,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components/Button';
import { TextAreaInput } from 'components/Form/TextAreaInput';
import { Column } from 'layouts/components/Grid/Column';
import { SectionHeaderRow } from 'layouts/components/Grid/SectionHeaderRow';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MonthlyChecklistItemViewFragment } from 'types/graphql';

interface IProps {
  monthlyChecklistItem: MonthlyChecklistItemViewFragment;
  readonly?: boolean;
  onCommentUpdated: (
    id: number,
    comment: string | undefined,
  ) => Promise<boolean>;
}

export const MonthlyChecklistItemComment = (props: IProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [comment, setComment] = useState(
    props.monthlyChecklistItem.comment ?? '',
  );

  useEffect(() => {
    setComment(props.monthlyChecklistItem.comment ?? '');
  }, [props.monthlyChecklistItem.comment]);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setComment(props.monthlyChecklistItem.comment ?? '');
    setShowModal(false);
  };

  const handleSave = async (): Promise<void> => {
    const succeeded = await props.onCommentUpdated(
      props.monthlyChecklistItem.monthlyChecklistItemId ?? 0,
      comment,
    );

    if (succeeded) {
      setShowModal(false);
    }
  };

  return (
    <>
      <span className="pointer" onClick={handleShowModal}>
        {!!props.monthlyChecklistItem.comment ? (
          <FontAwesomeIcon icon={faCommentAltLines} />
        ) : (
          <FontAwesomeIcon icon={faCommentAlt} />
        )}
      </span>
      <Modal
        show={showModal}
        backdrop="static"
        centered={true}
        keyboard={false}
      >
        <Modal.Body>
          {!props.readonly && (
            <div className="mb-3">
              <span
                className="float-right text-muted text-small pointer"
                onClick={handleClose}
              >
                Cancel <FontAwesomeIcon icon={faTimes} className="ml-2" />
              </span>
            </div>
          )}
          <SectionHeaderRow>
            <Column>
              <h2>
                Comments for Journal ID: {props.monthlyChecklistItem.journalId}{' '}
              </h2>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
          <TextAreaInput
            rows={10}
            value={comment}
            onChange={setComment}
            className="outlined no-resize"
            readOnly={props.readonly}
          ></TextAreaInput>
          <div className="row mt-3">
            <Column className="text-center">
              {!props.readonly && (
                <Button
                  variant="primary"
                  className="mr-3"
                  disabled={comment === props.monthlyChecklistItem.comment}
                  onClick={handleSave}
                >
                  Save
                </Button>
              )}
              <Button variant="warning" onClick={handleClose}>
                Close
              </Button>
            </Column>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
