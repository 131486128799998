import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import DataTable, { DataTableExportFunction } from 'components/DataTable';
import { IDataTableColumnDefinition } from 'components/DataTable/IDataTableColumnDefinition';
import TextInput from 'components/Form/TextInput';
import {
  adjustToLocalDate,
  standardFormatDate,
} from 'jec-common/lib/utils/date-utils';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  JournalEntrySortBy,
  JournalEntryTableDataDocument,
  JournalEntryTableDataItemFragment,
  JournalEntryTableDataQuery,
  JournalEntryTableDataQueryVariables,
  SortDirection,
  UpdateJournalEntryDocument,
  UpdateJournalEntryMutation,
  UpdateJournalEntryMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import { JournalEntryComment } from 'components/JournalEntryComment';
import { IJournalEntryFilterValues } from '../ReviewJournalEntryFilters';

export interface IJournalEntryTableControl {
  filter: (values: IJournalEntryFilterValues) => void;
  refresh: () => void;
  export: (args: { filename: string }) => Promise<void>;
}

interface IProps {
  controlRef?: React.MutableRefObject<IJournalEntryTableControl | undefined>;
}

export const JournalEntryTable: React.FC<IProps> = (props: IProps) => {
  const pageSize = 20;
  const { client } = useApolloClient();

  const [totalItems, setTotalItems] = useState<number>(0);
  const [items, setItems] = useState<JournalEntryTableDataItemFragment[]>([]);

  const [sortBy, setSortBy] = useState<JournalEntrySortBy>(
    JournalEntrySortBy.JournalId,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [filterValues, setFilterValues] = useState<IJournalEntryFilterValues>(
    {},
  );

  const [alertModal, setAlertModal] = useState<any>();
  const { warning, apolloError } = useModalAlerts(setAlertModal);

  const fetch = async (options: {
    skip?: number;
    take?: number;
    sortBy: JournalEntrySortBy;
    sortDirection: SortDirection;
  }) => {
    return client.query<
      JournalEntryTableDataQuery,
      JournalEntryTableDataQueryVariables
    >({
      query: JournalEntryTableDataDocument,
      variables: {
        filter: filterValues,
        paginationOptions: {
          skip: options.skip,
          take: options.take,
        },
        sortOptions: {
          sortBy: options.sortBy,
          sortDirection: options.sortDirection,
        },
      },
      fetchPolicy: 'no-cache',
    });
  };

  const clearGridAndTotalCount = () => {
    setTotalItems(0);
    setItems([]);
  };

  const fetchNextItemSet = (
    append = true,
    skip: number = items.length,
    take: number = pageSize,
  ) => {
    if (Object.keys(filterValues).length === 0) {
      clearGridAndTotalCount();
      return;
    }

    fetch({
      skip,
      take,
      sortBy,
      sortDirection,
    })
      .then((value) => {
        if (!!!value.data.journalEntries.totalItems) {
          warning({
            message:
              'The search criteria you entered yields no results. Please modify your search criteria.',
          });
          clearGridAndTotalCount();
        } else {
          setTotalItems(value.data.journalEntries.totalItems);
          setItems(
            append
              ? [...items, ...value.data.journalEntries.items]
              : value.data.journalEntries.items,
          );
        }
      })
      .catch((reason) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const refresh = () => {
    fetchNextItemSet(false, 0);
  };

  const refreshCurrentItems = () => {
    fetchNextItemSet(false, 0, items.length);
  };

  useEffect(refresh, [filterValues, sortBy, sortDirection]);

  const handleSort = (
    sortBy: JournalEntrySortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
  };

  const handleJournalIdChanged = (
    journalEntry: JournalEntryTableDataItemFragment,
    journalId: string,
  ) => {
    const currentJournalEntryIndex = items.findIndex(
      (i) => i.id === journalEntry.id,
    );
    journalEntry.journalId = journalId;
    const newItems = [...items];
    newItems[currentJournalEntryIndex] = { ...journalEntry };
    setItems(newItems);
  };

  const handleJournalIdUpdated = (id: number, journalId: string) => {
    return client
      .mutate<UpdateJournalEntryMutation, UpdateJournalEntryMutationVariables>({
        mutation: UpdateJournalEntryDocument,
        variables: {
          input: {
            id: id,
            journalId: journalId,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        refreshCurrentItems();
      })
      .catch((error) => {
        apolloError({
          error,
        });
      });
  };

  let exportFunction: DataTableExportFunction<JournalEntryTableDataItemFragment>;
  const handleExport = async (args: { filename: string }) => {
    return fetch({ sortBy, sortDirection }).then((value) => {
      return exportFunction({
        items: value.data.journalEntries.items,
        filename: args.filename,
      });
    });
  };

  if (props.controlRef) {
    props.controlRef.current = {
      filter: setFilterValues,
      refresh,
      export: handleExport,
    };
  }

  const columns: IDataTableColumnDefinition<
    JournalEntryTableDataItemFragment,
    JournalEntrySortBy
  >[] = [
    {
      dataFieldName: 'period',
      heading: 'Period',
      render: (item: JournalEntryTableDataItemFragment) =>
        `${String(item.monthlyChecklistItem.period?.month).padStart(2, '0')}-${
          item.monthlyChecklistItem.period?.year
        }`,
      sortBy: JournalEntrySortBy.Period,
      excelExport: (item) =>
        `${String(item.monthlyChecklistItem.period?.month).padStart(2, '0')}-${
          item.monthlyChecklistItem.period?.year
        }`,
    },
    {
      dataFieldName: 'daysAfterClose',
      heading: 'Days After Close',
      width: '8rem',
      render: (item) => item.monthlyChecklistItem.dueDaysAfterClose.toString(),
      excelExport: (item) => item.monthlyChecklistItem.dueDaysAfterClose,
      sortBy: JournalEntrySortBy.DueDaysAfterClose,
    },
    {
      dataFieldName: 'entity',
      heading: 'Entity',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.kbsEntity.name,
      sortBy: JournalEntrySortBy.Entity,
      excelExport: (item) => item.monthlyChecklistItem.kbsEntity.name,
    },
    {
      dataFieldName: 'title',
      heading: 'Title',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.title,
      sortBy: JournalEntrySortBy.Title,
      excelExport: (item) => item.monthlyChecklistItem.title,
    },
    {
      dataFieldName: 'type',
      heading: 'Type of Entry',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.jeType.name,
      sortBy: JournalEntrySortBy.JeType,
      excelExport: (item) => item.monthlyChecklistItem.jeType.name,
    },
    {
      dataFieldName: 'journalId',
      heading: 'Journal ID',
      cellClassName: (item) => (item?.isUnplanned ? 'bg-warning' : undefined),
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.period?.closedDate ? (
          item.journalId
        ) : (
          <TextInput
            value={item.journalId}
            onChange={(value) => {
              handleJournalIdChanged(item, value);
            }}
            onBlur={async (value) => {
              if (item.id && value) {
                handleJournalIdUpdated(item.id, value);
              }
            }}
            className="pl-0 mt-n2"
          />
        ),
      sortBy: JournalEntrySortBy.JournalId,
      excelExport: (item) => item.journalId.toString(),
    },
    {
      dataFieldName: 'netsuiteTransactionNumber',
      heading: 'Trans. Num.',
      width: '10rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.netsuiteTransactionNumber ?? '',
      sortBy: JournalEntrySortBy.TransactionNumber,
      excelExport: (item) => item.netsuiteTransactionNumber,
    },
    {
      dataFieldName: 'group',
      heading: 'Group',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.kbsGroup.name,
      sortBy: JournalEntrySortBy.Group,
      excelExport: (item) => item.monthlyChecklistItem.kbsGroup.name,
    },
    {
      dataFieldName: 'responsible',
      heading: 'Responsible',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.responsibleUser.name,
      sortBy: JournalEntrySortBy.Responsible,
      excelExport: (item) => item.monthlyChecklistItem.responsibleUser.name,
    },
    {
      dataFieldName: 'completedBy',
      heading: 'Completed By',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.completedBy ?? '',
      sortBy: JournalEntrySortBy.CompletedBy,
      excelExport: (item) => item.completedBy,
    },
    {
      dataFieldName: 'completed',
      width: '2rem',
      render: (item) => {
        return !!item.completedBy ? (
          <FontAwesomeIcon icon={faCheckSquare} />
        ) : (
          <></>
        );
      },
    },
    {
      dataFieldName: 'postedDate',
      heading: 'Date Posted',
      width: '11rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        standardFormatDate(adjustToLocalDate(item.postedDate)),
      sortBy: JournalEntrySortBy.PostedDate,
      excelExport: (item) => adjustToLocalDate(item.postedDate),
    },
    {
      dataFieldName: 'recordedDate',
      heading: 'Rec. Date',
      width: '10rem',
      render: (item: JournalEntryTableDataItemFragment) =>
        standardFormatDate(adjustToLocalDate(item.recordedDate)),
      sortBy: JournalEntrySortBy.RecordedDate,
      excelExport: (item) => adjustToLocalDate(item.recordedDate),
    },
    {
      dataFieldName: 'comment',
      heading: 'Comment',
      width: '8rem',
      cellClassName: () => 'text-center',
      render: (item: JournalEntryTableDataItemFragment) =>
        item.monthlyChecklistItem.period?.closedDate ? (
          (item.comment && (
            <JournalEntryComment
              id={item.id}
              journalId={item.journalId}
              comment={item.comment}
              readonly={true}
            />
          )) || <></>
        ) : (
          <JournalEntryComment
            id={item.id}
            journalId={item.journalId}
            comment={item.comment ?? null}
            onCommentSaved={refreshCurrentItems}
          />
        ),
      excelExport: (item) => item.comment,
    },
  ];

  if (Object.keys(filterValues).length === 0) {
    return <></>;
  }

  return (
    <>
      {alertModal}
      <InfiniteScroll
        dataLength={items.length}
        next={fetchNextItemSet}
        hasMore={items.length < totalItems}
        loader={<span>loading more checklist rows...</span>}
        endMessage={<span></span>}
      >
        <DataTable<JournalEntryTableDataItemFragment, JournalEntrySortBy>
          columns={columns}
          data={items}
          getDataItemId={(item) => item.id}
          sort={{ sortBy, sortDirection }}
          small={false}
          onSort={handleSort}
          rowClassName={(item) =>
            !!item.monthlyChecklistItem.period?.closedDate ? 'text-muted' : ''
          }
          exportRef={(fn) => {
            exportFunction = fn;
          }}
        ></DataTable>
      </InfiniteScroll>
    </>
  );
};

export default JournalEntryTable;
