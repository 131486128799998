import AuthorizedLayout from 'layouts/AuthorizedLayout';
import MainLayout from 'layouts/MainLayout';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Home from 'views/Home';
import MasterChecklistItem from 'views/MasterChecklistItem';
import { MasterChecklist } from 'views/MasterChecklist';
import { MonthlyChecklist } from 'views/MonthlyChecklist';
import { ReviewJournalEntries } from 'views/ReviewJournalEntries';
import SecuredRoute from 'layouts/components/SecuredRoute';
import { BulkUpdate } from 'views/BulkUpdate';
import { StatusReport } from 'views/StatusReport';
import { apiBaseUrl } from 'utils/site-util';

const Routes = (): JSX.Element => {
  return (
    <MainLayout>
      <Switch>
        <Route
          path="/login"
          component={() => {
            window.location.href = `${apiBaseUrl()}/auth/login`;
            return <>Redirecting to {apiBaseUrl()}</>;
          }}
        />
        <AuthorizedLayout>
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Route path="/home" exact={true}>
              <Home />
            </Route>
            <SecuredRoute path="/review" exact={true}>
              <ReviewJournalEntries />
            </SecuredRoute>
            <SecuredRoute path="/status-report" exact={true}>
              <StatusReport />
            </SecuredRoute>

            <SecuredRoute
              path="/master-checklist"
              role={process.env.REACT_APP_ADMIN_ROLE_NAME}
              exact={true}
            >
              <MasterChecklist />
            </SecuredRoute>
            <SecuredRoute
              path="/journal-entry-master/:id?"
              role={process.env.REACT_APP_ADMIN_ROLE_NAME}
              exact={true}
            >
              <MasterChecklistItem />
            </SecuredRoute>
            <SecuredRoute
              path="/monthly-checklist"
              role={process.env.REACT_APP_ADMIN_ROLE_NAME}
              exact={true}
            >
              <MonthlyChecklist />
            </SecuredRoute>
            <SecuredRoute
              path="/bulk-update"
              role={process.env.REACT_APP_ADMIN_ROLE_NAME}
              exact={true}
            >
              <BulkUpdate />
            </SecuredRoute>
          </Switch>
        </AuthorizedLayout>
      </Switch>
    </MainLayout>
  );
};

export default Routes;
