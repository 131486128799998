import { DocumentNode } from 'graphql';
import React from 'react';
import {
  LookupModel,
  PeriodLookupsDocument,
  PeriodLookupsQuery,
  PeriodLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  children: (value: {
    queryDocument: DocumentNode;
    queryVariables: PeriodLookupsQueryVariables;
    extractLookups: (query: PeriodLookupsQuery) => LookupModel[];
  }) => React.ReactNode;
}

export const PeriodEntityLookupWrapper = (props: IProps): JSX.Element => {
  return (
    <>
      {props.children({
        queryDocument: PeriodLookupsDocument,
        queryVariables: { includeDeleted: props.includeDeleted },
        extractLookups: (query) => query.periodLookups,
      })}
    </>
  );
};
