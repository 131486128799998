import { Page } from 'layouts/Page';
import React from 'react';
import { MonthlyChecklistDetail } from './components/MonthlyChecklistDetail';

export const MonthlyChecklist = (): JSX.Element => {
  return (
    <Page title="Monthly Checklist">
      <MonthlyChecklistDetail />
    </Page>
  );
};
