import { CheckBlock } from 'components/Form/CheckBlock';
import { PlainText } from 'components/Form/PlainText';
import Column from 'layouts/components/Grid/Column';
import React, { PropsWithChildren } from 'react';

interface IProps extends PropsWithChildren<any> {
  checked?: boolean;
  label?: string;
  isRequired?: boolean;
  onCheckedChange: (isChecked: boolean) => void;
  rowClassName?: string;
}

export const CheckBoxWithInputRow = (props: IProps): JSX.Element => {
  return (
    <div className={`row ${props.rowClassName ?? ''}`}>
      <Column width={5}>
        <PlainText>
          <CheckBlock
            className="font-weight-bold"
            label={props.label}
            isRequired={props.isRequired}
            checked={props.checked}
            onCheckedChange={props.onCheckedChange}
          />
        </PlainText>
      </Column>
      <Column>{props.children}</Column>
    </div>
  );
};
