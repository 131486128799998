import Column from 'layouts/components/Grid/Column';
import React from 'react';
import { MonthSelect } from './MonthSelect';
import { YearSelect } from './YearSelect';

export interface IMonthAndYearSelection {
  month?: number | null;
  year?: number | null;
}

interface IProps {
  earliestYear?: number | undefined;
  monthValue?: number | null;
  yearValue?: number | null;
  onSelect: (value?: IMonthAndYearSelection) => void;
}

export const MonthAndYearSelect = (props: IProps): JSX.Element => {
  const handleMonthSelect = (value?: number | null) => {
    props.onSelect({
      month: value,
      year: props.yearValue,
    });
  };

  const handleYearSelect = (value?: number | null) => {
    props.onSelect({
      month: props.monthValue,
      year: value,
    });
  };

  return (
    <>
      <div className="row">
        <Column>
          <MonthSelect value={props.monthValue} onSelect={handleMonthSelect} />
        </Column>
        <Column>
          <YearSelect
            earliestYear={props.earliestYear}
            value={props.yearValue}
            onSelect={handleYearSelect}
          />
        </Column>
      </div>
    </>
  );
};
