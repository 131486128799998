import React from 'react';

interface IProps {
  className?: string;
  children: any;
  onClick?: () => void;
}

export const PlainText = (props: IProps): JSX.Element => {
  return (
    <span
      className={`form-control-plaintext ${props.className ?? ''}`}
      onClick={props.onClick}
    >
      {props.children}
    </span>
  );
};
