"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.monthNumberToName = exports.zeroTime = exports.standardFormatLastDayOfMonthDate = exports.standardFormatFirstDayOfMonthDate = exports.standardFormatDate = exports.getNowDate = exports.adjustToLocalDate = exports.isValidDate = void 0;
const date_fns_1 = require("date-fns");
exports.isValidDate = (date) => {
    return Object.prototype.toString.call(date) === '[object Date]' && isNaN(date.getTime()) === false;
};
// Converting strings to dates in javascript is nasty!
// Dates are stored in database without any timezone information
// (ex 2019-01-11 00:00:00).  When the data is retrieved through web, then
// they will be in ISO format (ex 2019-01-11T00:00:00.000Z).  Once this datetime
// is converted to a Date object then the date object will be in
// local time which in the case of PST will be 2019-01-10 16:00:00 (i.e. the
// previous day).
// This function will convert an ISO date string in to a Date object where the
// date and time will match what was in the ISO string exactly. So an ISO
// date string of 2019-01-11T00:00:00.000Z will result in a Date object like
// 2019-01-11 PST.
exports.adjustToLocalDate = (iso) => {
    if (!iso) {
        return null;
    }
    let d = iso instanceof Date ? iso : new Date(iso);
    d.setMinutes(d.getMinutes() + d.getTimezoneOffset());
    return d;
};
exports.getNowDate = () => {
    const result = new Date();
    exports.zeroTime(result);
    return result;
};
exports.standardFormatDate = (date) => {
    return !!date ? date_fns_1.format(typeof date === 'string' ? new Date(date) : date, 'MM-dd-yyyy') : '';
};
/**
 *
 * @param month The month of the year (1 thru 12)
 * @param year
 */
exports.standardFormatFirstDayOfMonthDate = (month, year) => {
    return date_fns_1.format(new Date(year, month - 1), 'MM-dd-yyyy');
};
/**
 *
 * @param month The month of the year (1 thru 12)
 * @param year
 */
exports.standardFormatLastDayOfMonthDate = (month, year) => {
    return date_fns_1.format(date_fns_1.endOfMonth(new Date(year, month - 1)), 'MM-dd-yyyy');
};
exports.zeroTime = (date) => {
    if (!!!date) {
        return date;
    }
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
};
exports.monthNumberToName = (month) => {
    let result = '';
    switch (month) {
        case 1:
            result = 'January';
            break;
        case 2:
            result = 'February';
            break;
        case 3:
            result = 'March';
            break;
        case 4:
            result = 'April';
            break;
        case 5:
            result = 'May';
            break;
        case 6:
            result = 'June';
            break;
        case 7:
            result = 'July';
            break;
        case 8:
            result = 'August';
            break;
        case 9:
            result = 'September';
            break;
        case 10:
            result = 'October';
            break;
        case 11:
            result = 'November';
            break;
        case 12:
            result = 'December';
            break;
    }
    return result;
};
