import { faSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RequiredMarker } from 'components/RequiredMarker/RequiredMarker';
import React from 'react';

interface IProps {
  checked?: boolean;
  label?: string;
  onCheckedChange: (isChecked: boolean) => void;
  className?: string;
  disabled?: boolean;
  isRequired?: boolean;
}

export const CheckBlock = (props: IProps): JSX.Element => {
  const handleCheckedChange = () => {
    props.onCheckedChange(!props.checked);
  };

  return (
    <div className={`${props.className ?? ''}`}>
      {props.checked && (
        <FontAwesomeIcon
          icon={faCheckSquare}
          onClick={props.disabled ? undefined : handleCheckedChange}
          className="mr-2"
        />
      )}
      {!props.checked && (
        <FontAwesomeIcon
          icon={faSquare}
          onClick={props.disabled ? undefined : handleCheckedChange}
          className="mr-2"
        />
      )}
      {!!props.label && (
        <span>
          {props.label} <RequiredMarker isRequired={props.isRequired} />
        </span>
      )}
    </div>
  );
};
