import { IChipAutosuggestOption } from 'components/ChipAutosuggest';
import { LookupChipAutosuggest } from 'components/LookupChipAutosuggest';
import React from 'react';
import {
  JournalIdLookupsDocument,
  JournalIdLookupsQuery,
  JournalIdLookupsQueryVariables,
} from 'types/graphql';

interface IProps {
  includeDeleted?: boolean;
  selectedOptions?: IChipAutosuggestOption[];
  onChange: (selectedOptions: readonly IChipAutosuggestOption[]) => void;
}

export const JournalIdChipAutosuggest = (props: IProps): JSX.Element => {
  return (
    <LookupChipAutosuggest<
      JournalIdLookupsQuery,
      JournalIdLookupsQueryVariables
    >
      extractChipOptions={(query) =>
        query.journalIdLookups.map((a) => ({
          id: a.name,
          label: a.name,
        }))
      }
      onChange={props.onChange}
      queryDocument={JournalIdLookupsDocument}
      queryVariables={(inputValue?: string) => ({
        name: inputValue,
      })}
      selectedOptions={props.selectedOptions}
    />
  );
};
