import SwissArmySelect from 'components/SwissArmySelect';
import React from 'react';

export interface IChipOption {
  id: string;
  label: string;
}

export interface IChipSelectProps {
  disabled?: boolean;
  allowNewEntries?: boolean;
  availableOptions: IChipOption[];
  selectedOptions: IChipOption[];
  placeholder?: string;
  onSelect: (selectedOptions: ReadonlyArray<IChipOption>) => void;
}

export const ChipSelect: React.FC<IChipSelectProps> = (
  props: IChipSelectProps,
) => {
  const handleLoadOptions = async (
    inputValue: string,
    callback: (options: IChipOption[]) => void,
  ): Promise<void> => {
    // loadOptions is used here with the static availableOptions so as to allow
    // the entry of new items.
    const upperCaseInputValue = inputValue.trim().toUpperCase();

    const loadedOptions: IChipOption[] = !upperCaseInputValue
      ? props.availableOptions
      : props.availableOptions.filter((availableOption) =>
          availableOption.label.toUpperCase().includes(upperCaseInputValue),
        );

    let options: IChipOption[] = loadedOptions;

    if (props.allowNewEntries) {
      const existingOptions = options.concat(props.selectedOptions);

      const inputValueMatchesExistingOption = existingOptions.find(
        (option) => option.label === inputValue,
      );

      if (!inputValueMatchesExistingOption) {
        const newId = existingOptions
          .concat({ id: '', label: 'seeding option' }) // ensure new entries start at -1
          .reduce(
            (previousOption, thisOption): IChipOption =>
              thisOption.id < previousOption.id ? thisOption : previousOption,
          ).id;
        options = [{ id: newId, label: inputValue }].concat(loadedOptions);
      }
    }

    callback(options);
  };

  const handleChange = (
    value: IChipOption | ReadonlyArray<IChipOption> | null | undefined,
  ): void => {
    if (!value) {
      props.onSelect([]);
      return;
    }

    if (Array.isArray(value)) {
      props.onSelect(value);
      return;
    }

    props.onSelect([value as IChipOption]);
  };

  return (
    <SwissArmySelect<IChipOption>
      readonly={props.disabled}
      className="flat"
      allowMultipleSelection={true}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id.toString()}
      defaultOptions={props.availableOptions}
      loadOptions={handleLoadOptions}
      value={props.selectedOptions}
      onChange={handleChange}
      placeholder={props.placeholder}
    />
  );
};
