import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertModal } from 'components/Alerts/AlertModal';
import useModalAlerts from 'components/Alerts/useModalAlerts';
import { Button } from 'components/Button';
import { DataTableExportFunction } from 'components/DataTable';
import { ExportToExcelButton } from 'components/DataTable/ExportToExcelButton';
import { DatePicker } from 'components/Form/DatePicker';
import { PlainText } from 'components/Form/PlainText';
import { PeriodStatusLookupEnum } from 'jec-common';
import {
  isValidDate,
  monthNumberToName,
  standardFormatDate,
} from 'jec-common/lib/utils/date-utils';
import Column from 'layouts/components/Grid/Column';
import FieldSetColumn from 'layouts/components/Grid/FieldSetColumn';
import FormLabelColumn from 'layouts/components/Grid/FormLabelColumn';
import SectionHeaderRow from 'layouts/components/Grid/SectionHeaderRow';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  AddMonthlyChecklistItemDocument,
  AddMonthlyChecklistItemMutation,
  AddMonthlyChecklistItemMutationVariables,
  AddPeriodJeEmailReminderDocument,
  AddPeriodJeEmailReminderMutation,
  AddPeriodJeEmailReminderMutationVariables,
  ClosePeriodDocument,
  ClosePeriodMutation,
  ClosePeriodMutationVariables,
  DeletePeriodJeEmailReminderDocument,
  DeletePeriodJeEmailReminderMutation,
  DeletePeriodJeEmailReminderMutationVariables,
  FetchMonthlyChecklistItemsDocument,
  FetchMonthlyChecklistItemsQuery,
  FetchMonthlyChecklistItemsQueryVariables,
  InitiatePeriodDocument,
  InitiatePeriodMutation,
  InitiatePeriodMutationVariables,
  MonthlyChecklistItemViewFragment,
  MonthlyChecklistItemViewSortBy,
  PeriodJeEmailReminderFragment,
  PeriodJeEmailRemindersDocument,
  PeriodJeEmailRemindersQuery,
  PeriodJeEmailRemindersQueryVariables,
  ReopenPeriodDocument,
  ReopenPeriodMutation,
  ReopenPeriodMutationVariables,
  SendPeriodJeEmailReminderNowDocument,
  SendPeriodJeEmailReminderNowMutation,
  SendPeriodJeEmailReminderNowMutationVariables,
  SortDirection,
  UpdateMonthlyChecklistItemDocument,
  UpdateMonthlyChecklistItemInput,
  UpdateMonthlyChecklistItemMutation,
  UpdateMonthlyChecklistItemMutationVariables,
  UpdatePeriodCloseDateDocument,
  UpdatePeriodCloseDateMutation,
  UpdatePeriodCloseDateMutationVariables,
  UpdatePeriodJeEmailReminderDocument,
  UpdatePeriodJeEmailReminderMutation,
  UpdatePeriodJeEmailReminderMutationVariables,
} from 'types/graphql';
import useApolloClient from 'useApolloClient';
import MonthlyChecklistItemTable from '../MonthlyChecklistItemTable';
import { ISelectedPeriod, PeriodSelection } from '../PeriodSelection';
import { ReminderDates } from '../ReminderDates';

export const MonthlyChecklistDetail = (): JSX.Element => {
  const [selectedPeriod, setSelectedPeriod] = useState<ISelectedPeriod | null>(
    null,
  );
  const [reminders, setReminders] = useState<PeriodJeEmailReminderFragment[]>(
    [],
  );
  const [allChecklistItems, setOnlyScheduledChecklistItems] =
    useState<boolean>(false);

  const [totalItems, setTotalItems] = useState<number>(0);
  const [checklistItems, setCheckListItems] = useState<
    MonthlyChecklistItemViewFragment[]
  >([]);

  const pageSize = 20;

  const [sortBy, setSortBy] = useState<MonthlyChecklistItemViewSortBy>(
    MonthlyChecklistItemViewSortBy.JournalId,
  );
  const [sortDirection, setSortDirection] = useState<SortDirection>(
    SortDirection.Asc,
  );

  const [showInitiatePeriodModal, setShowInitiatePeriodModal] =
    useState<boolean>(false);

  const [closeDate, setCloseDate] = useState<Date | null>(null);
  const [isInitiatingPeriod, setIsInitiatingPeriod] = useState(false);

  const { client } = useApolloClient();
  const [modalAlert, setModalAlert] = useState<React.ReactNode>();
  const { apolloError, confirm, success } = useModalAlerts(setModalAlert);
  let refreshPeriod: (() => void) | undefined;
  let exportFunction: DataTableExportFunction<MonthlyChecklistItemViewFragment>;

  const handlePeriodChange = (
    period: ISelectedPeriod,
    allChecklistItems: boolean,
  ) => {
    setSelectedPeriod(period);
    setOnlyScheduledChecklistItems(allChecklistItems);
    refreshItems({
      month: period.month,
      year: period.year,
      allChecklistItems,
      takeOverride: pageSize,
    });
    refreshReminders(period);
  };

  const handleInitiatePeriod = () => {
    if (!!selectedPeriod && !selectedPeriod.periodStatus) {
      setIsInitiatingPeriod(true);
      client
        .mutate<InitiatePeriodMutation, InitiatePeriodMutationVariables>({
          mutation: InitiatePeriodDocument,
          variables: {
            month: selectedPeriod.month,
            year: selectedPeriod.year,
            closeDate,
          },
        })
        .then(() => {
          setCloseDate(null);
          setShowInitiatePeriodModal(false);

          if (refreshPeriod) {
            refreshPeriod();
          }

          refreshItems({
            month: selectedPeriod.month,
            year: selectedPeriod.year,
            allChecklistItems,
          });
        })
        .catch((error) => {
          apolloError({ error });
        })
        .finally(() => {
          setIsInitiatingPeriod(false);
        });
    }
  };

  const handleClosePeriod = () => {
    if (selectedPeriod?.periodStatus === PeriodStatusLookupEnum.Open) {
      const handleConfirm = () => {
        return client
          .mutate<ClosePeriodMutation, ClosePeriodMutationVariables>({
            mutation: ClosePeriodDocument,
            variables: {
              month: selectedPeriod?.month ?? 0,
              year: selectedPeriod?.year ?? 0,
            },
          })
          .then(() => {
            if (refreshPeriod) {
              refreshPeriod();
            }

            refreshItems({
              month: selectedPeriod?.month ?? 0,
              year: selectedPeriod?.year ?? 0,
              allChecklistItems,
            });
          })
          .catch((error) => {
            apolloError({ error });
          });
      };

      confirm({
        message: `You are about to close the period ${monthNumberToName(
          selectedPeriod?.month ?? 0,
        )}, ${
          selectedPeriod?.year ?? 0
        }. Users will no longer be able to edit any data in the period, and NetSuite scanning for this period will be halted. Are you certain you wish to close the period?`,
        onConfirm: handleConfirm,
        variant: 'warning',
      });
    }
  };

  const handleReopenPeriod = () => {
    if (selectedPeriod) {
      const handleConfirm = () => {
        client
          .mutate<ReopenPeriodMutation, ReopenPeriodMutationVariables>({
            mutation: ReopenPeriodDocument,
            variables: {
              month: selectedPeriod.month,
              year: selectedPeriod.year,
            },
          })
          .then(() => {
            if (refreshPeriod) {
              refreshPeriod();
            }

            refreshItems({
              month: selectedPeriod.month,
              year: selectedPeriod.year,
              allChecklistItems,
            });
          })
          .catch((error) => {
            apolloError({ error });
          });
      };

      confirm({
        message: `You are about to reopen period ${monthNumberToName(
          selectedPeriod.month,
        )}, ${
          selectedPeriod.year
        }. Once open, the scan will start checking NetSuite for data, and users will be able to edit journal entries in this period. Are you certain you wish to reopen the period?`,
        onConfirm: handleConfirm,
        variant: 'warning',
      });
    }
  };

  const handleChangeCloseDate = (closeDate: Date | null) => {
    if (selectedPeriod && closeDate) {
      client
        .mutate<
          UpdatePeriodCloseDateMutation,
          UpdatePeriodCloseDateMutationVariables
        >({
          mutation: UpdatePeriodCloseDateDocument,
          variables: {
            month: selectedPeriod.month,
            year: selectedPeriod.year,
            closeDate,
          },
        })
        .then(() => {
          if (refreshPeriod) {
            refreshPeriod();
          }
        })
        .catch((error) => {
          apolloError({ error });
        });
    }
  };

  const handleSort = (
    sortBy: MonthlyChecklistItemViewSortBy,
    sortDirection: SortDirection,
  ) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);

    if (selectedPeriod) {
      refreshItems({
        month: selectedPeriod.month,
        year: selectedPeriod.year,
        allChecklistItems,
        sortByOverride: sortBy,
        sortDirectionOverride: sortDirection,
        takeOverride: pageSize,
      });
    }
  };

  const fetch = async (options: {
    month: number;
    year: number;
    allChecklistItems: boolean;
    skip?: number;
    take?: number;
    sortBy?: MonthlyChecklistItemViewSortBy;
    sortDirection?: SortDirection;
  }) => {
    return client.query<
      FetchMonthlyChecklistItemsQuery,
      FetchMonthlyChecklistItemsQueryVariables
    >({
      query: FetchMonthlyChecklistItemsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        month: options.month,
        year: options.year,
        allChecklistItems: options.allChecklistItems,
        sortBy: options.sortBy ?? sortBy,
        sortDirection: options.sortDirection ?? sortDirection,
        skip: options.skip,
        take: options.take,
      },
    });
  };

  const fetchNextItemSet = (
    append = true,
    skip: number = checklistItems.length,
  ) => {
    if (!selectedPeriod) {
      return;
    }

    return fetch({
      month: selectedPeriod.month,
      year: selectedPeriod.year,
      allChecklistItems,
      skip,
      take: pageSize,
    })
      .then((value) => {
        setTotalItems(value.data.monthlyChecklistItemViews.totalItems);
        setCheckListItems(
          append
            ? [...checklistItems, ...value.data.monthlyChecklistItemViews.items]
            : value.data.monthlyChecklistItemViews.items,
        );
      })
      .catch((reason) =>
        apolloError({
          error: reason,
        }),
      );
  };

  const refreshItems = async (options: {
    month: number;
    year: number;
    allChecklistItems: boolean;
    sortByOverride?: MonthlyChecklistItemViewSortBy;
    sortDirectionOverride?: SortDirection;
    takeOverride?: number;
  }): Promise<void> => {
    await fetch({
      month: options.month,
      year: options.year,
      allChecklistItems: options.allChecklistItems,
      skip: 0,
      take: options.takeOverride || checklistItems.length || pageSize,
      sortBy: options.sortByOverride,
      sortDirection: options.sortDirectionOverride,
    }).then((response) => {
      setTotalItems(response.data.monthlyChecklistItemViews.totalItems);
      setCheckListItems(response.data.monthlyChecklistItemViews.items);
    });
  };

  const handleItemUpdated = async (item: UpdateMonthlyChecklistItemInput) => {
    if (!selectedPeriod) {
      return;
    }

    return client
      .mutate<
        UpdateMonthlyChecklistItemMutation,
        UpdateMonthlyChecklistItemMutationVariables
      >({
        mutation: UpdateMonthlyChecklistItemDocument,
        variables: {
          input: item,
        },
        fetchPolicy: 'no-cache',
      })
      .then(async () => {
        await refreshItems({
          month: selectedPeriod.month,
          year: selectedPeriod.year,
          allChecklistItems,
        });
      })
      .catch((error) => {
        apolloError({
          error,
        });
      });
  };

  const handleItemAdded = async (masterChecklistItemId: number) => {
    if (!selectedPeriod) {
      return;
    }

    return client
      .mutate<
        AddMonthlyChecklistItemMutation,
        AddMonthlyChecklistItemMutationVariables
      >({
        mutation: AddMonthlyChecklistItemDocument,
        variables: {
          input: {
            masterChecklistItemId,
            month: selectedPeriod.month,
            year: selectedPeriod.year,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(() => {
        return refreshItems({
          month: selectedPeriod.month,
          year: selectedPeriod.year,
          allChecklistItems,
        });
      })
      .catch((error) => {
        apolloError({
          error,
        });
      });
  };

  const handleItemChanged = async (item: UpdateMonthlyChecklistItemInput) => {
    const fragmentIndex = checklistItems.findIndex(
      (i) => i.monthlyChecklistItemId === item.id,
    );
    const fragment = checklistItems[fragmentIndex];
    if (item.journalId) {
      fragment.journalId = item.journalId;
    }
    const newItems = [...checklistItems];
    newItems[fragmentIndex] = { ...fragment };
    setCheckListItems(newItems);
  };

  const onSaveComment = async (
    monthlyChecklistItemId: number,
    comment: string | undefined,
  ): Promise<boolean> => {
    if (!selectedPeriod) {
      return false;
    }

    return client
      .mutate<
        UpdateMonthlyChecklistItemMutation,
        UpdateMonthlyChecklistItemMutationVariables
      >({
        mutation: UpdateMonthlyChecklistItemDocument,
        variables: {
          input: {
            id: monthlyChecklistItemId,
            comment,
          },
        },
        fetchPolicy: 'no-cache',
      })
      .then(async () => {
        await refreshItems({
          month: selectedPeriod.month,
          year: selectedPeriod.year,
          allChecklistItems,
        });
        return true;
      })
      .catch((error) => {
        apolloError({
          error,
        });
        return false;
      });
  };

  const handleExport = async () => {
    if (!!exportFunction && !!selectedPeriod) {
      return fetch({
        month: selectedPeriod.month,
        year: selectedPeriod.year,
        allChecklistItems,
        sortBy,
        sortDirection,
      }).then((result) => {
        return exportFunction({
          items: result.data.monthlyChecklistItemViews.items,
          filename: 'monthly-checklist-items.xlsx',
        });
      });
    }
  };

  const handleAddNewReminder = async (value: Date | null) => {
    if (isValidDate(value) && selectedPeriod) {
      return client
        .mutate<
          AddPeriodJeEmailReminderMutation,
          AddPeriodJeEmailReminderMutationVariables
        >({
          mutation: AddPeriodJeEmailReminderDocument,
          variables: {
            month: selectedPeriod.month,
            year: selectedPeriod.year,
            sendDate: value,
          },
        })
        .then(() => {
          refreshReminders();
        })
        .catch((error) => {
          apolloError({ error });
        });
    }
  };

  const handleChangeReminder = (id: number) => (value: Date | null) => {
    if (!value) {
      return handleRemoveReminder(id)();
    }

    // Will get invalid date if user is manually typing in a new date but hasn't
    // finished typing the full date yet
    if (value.toString() === 'Invalid Date') {
      return;
    }

    client
      .mutate<
        UpdatePeriodJeEmailReminderMutation,
        UpdatePeriodJeEmailReminderMutationVariables
      >({
        mutation: UpdatePeriodJeEmailReminderDocument,
        variables: {
          id,
          sendDate: value,
        },
      })
      .then(() => {
        refreshReminders();
      })
      .catch((error) => {
        apolloError({ error });
      });
  };

  const handleRemoveReminder = (id: number) => () => {
    client
      .mutate<
        DeletePeriodJeEmailReminderMutation,
        DeletePeriodJeEmailReminderMutationVariables
      >({
        mutation: DeletePeriodJeEmailReminderDocument,
        variables: {
          id,
        },
      })
      .then(() => {
        refreshReminders();
      })
      .catch((error) => {
        apolloError({ error });
      });
  };

  const refreshReminders = (period?: ISelectedPeriod) => {
    const thePeriod = period ?? selectedPeriod;
    if (thePeriod) {
      client
        .query<
          PeriodJeEmailRemindersQuery,
          PeriodJeEmailRemindersQueryVariables
        >({
          query: PeriodJeEmailRemindersDocument,
          variables: {
            month: thePeriod.month,
            year: thePeriod.year,
          },
          fetchPolicy: 'no-cache',
        })
        .then((response) => {
          setReminders(response.data.periodJeEmailReminders);
        })
        .catch((error) => {
          apolloError({ error });
        });
    }
  };

  const handleSendRemindersNow = () => {
    client
      .mutate<
        SendPeriodJeEmailReminderNowMutation,
        SendPeriodJeEmailReminderNowMutationVariables
      >({
        mutation: SendPeriodJeEmailReminderNowDocument,
        variables: {
          input: {
            month: selectedPeriod?.month,
            year: selectedPeriod?.year,
          },
        },
      })
      .then(() => {
        success({
          message: 'Email has been queued to send',
          timeout: 2000,
        });
      })
      .catch((error) => {
        apolloError({
          error,
        });
      });
  };

  return (
    <>
      {modalAlert}
      <AlertModal
        show={showInitiatePeriodModal}
        variant="primary"
        title="Initiate Period"
        onOkClick={handleInitiatePeriod}
        okButtonEnabled={!!closeDate && !isInitiatingPeriod}
        onCancelClick={() => {
          setCloseDate(null);
          setShowInitiatePeriodModal(false);
        }}
        cancelButtonEnabled={!isInitiatingPeriod}
      >
        <div className="row">
          <FieldSetColumn gutters={false}>
            {!isInitiatingPeriod && (
              <>
                <p>
                  Please enter a close date for{' '}
                  {monthNumberToName(selectedPeriod?.month ?? 1)},{' '}
                  {selectedPeriod?.year}
                </p>
                <div className="row">
                  <Column>
                    <DatePicker value={closeDate} onChange={setCloseDate} />
                  </Column>
                </div>
              </>
            )}

            {isInitiatingPeriod && (
              <div className="text-center">
                <p>Initiating period...</p>
                <p>
                  <FontAwesomeIcon icon={faSpinner} className="h1 fa-spin" />
                </p>
              </div>
            )}
          </FieldSetColumn>
        </div>
      </AlertModal>

      <div className="row">
        <Column>
          <SectionHeaderRow>
            <Column>
              <h1>Overview</h1>
            </Column>
          </SectionHeaderRow>
          <hr className="mt-0" />
        </Column>
        <Column>
          {!!selectedPeriod &&
            selectedPeriod.periodStatus === PeriodStatusLookupEnum.Open && (
              <>
                <SectionHeaderRow>
                  <Column>
                    <h1>Send Email Reminders</h1>
                  </Column>
                </SectionHeaderRow>
                <hr className="mt-0" />
              </>
            )}
        </Column>
      </div>

      <div className="row">
        <Column width={6}>
          <div className="row">
            <FieldSetColumn>
              <PeriodSelection
                onPeriodChange={handlePeriodChange}
                refreshPeriodRef={(fn) => {
                  refreshPeriod = fn;
                }}
              />
            </FieldSetColumn>
          </div>
          {!!selectedPeriod && (
            <div className="row">
              <Column
                style={{ border: '2px solid #f2f2f2' }}
                className="ml-2 mr-2"
              >
                <div className="row mt-3">
                  <FormLabelColumn width={4}>Close Date</FormLabelColumn>
                  <Column width={4}>
                    {selectedPeriod.periodStatus ===
                      PeriodStatusLookupEnum.Open && (
                      <DatePicker
                        value={selectedPeriod.closeDate}
                        onChange={handleChangeCloseDate}
                      />
                    )}
                    {selectedPeriod.periodStatus ===
                      PeriodStatusLookupEnum.Closed && (
                      <PlainText>
                        <span style={{ paddingLeft: '0.75rem' }}>
                          {standardFormatDate(selectedPeriod.closeDate)}
                        </span>
                      </PlainText>
                    )}
                  </Column>
                </div>
                <div className="row">
                  <FormLabelColumn width={4}>
                    Period Initiated On
                  </FormLabelColumn>
                  <Column width={4}>
                    <PlainText>
                      <span style={{ paddingLeft: '0.75rem' }}>
                        {!!selectedPeriod?.initiatedDate
                          ? standardFormatDate(selectedPeriod.initiatedDate)
                          : 'Period Not Yet Initiated'}
                      </span>
                    </PlainText>
                  </Column>
                </div>
                <div className="row">
                  <FormLabelColumn width={4}>Period Closed On</FormLabelColumn>
                  <Column width={4}>
                    <PlainText>
                      <span style={{ paddingLeft: '0.75rem' }}>
                        {!!selectedPeriod?.closedDate
                          ? standardFormatDate(selectedPeriod.closedDate)
                          : 'Period Not Yet Closed'}
                      </span>
                    </PlainText>
                  </Column>
                </div>
              </Column>
            </div>
          )}
        </Column>

        {!!selectedPeriod &&
          selectedPeriod.periodStatus === PeriodStatusLookupEnum.Open && (
            <>
              <Column style={{ maxWidth: '35%' }}>
                <div className="row">
                  <FieldSetColumn>
                    <ReminderDates
                      reminders={reminders}
                      handleAddNewReminder={handleAddNewReminder}
                      handleChangeReminder={handleChangeReminder}
                      handleRemoveReminder={handleRemoveReminder}
                    />
                  </FieldSetColumn>
                </div>
              </Column>

              <Column width={1}>
                <Button
                  variant="primary"
                  label="Send Reminders Now"
                  isMultiLine={true}
                  onClick={handleSendRemindersNow}
                />
              </Column>
            </>
          )}
      </div>

      {!!selectedPeriod && (
        <>
          <div className="row mt-5">
            <Column>
              <SectionHeaderRow>
                <Column>
                  <h1>Monthly Checklist</h1>
                </Column>
              </SectionHeaderRow>
              <hr className="mt-0" />
            </Column>
          </div>

          <div className="row mt-3">
            <Column>
              {!!selectedPeriod.periodStatus && (
                <ExportToExcelButton onClick={handleExport} />
              )}
            </Column>
            <Column className="text-right">
              {!selectedPeriod.periodStatus && (
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowInitiatePeriodModal(true);
                  }}
                >
                  Initiate
                </Button>
              )}
              {selectedPeriod.periodStatus === PeriodStatusLookupEnum.Open && (
                <Button variant="warning" onClick={handleClosePeriod}>
                  Close Period
                </Button>
              )}
              {selectedPeriod.periodStatus ===
                PeriodStatusLookupEnum.Closed && (
                <Button variant="warning" onClick={handleReopenPeriod}>
                  Reopen Period
                </Button>
              )}
            </Column>
          </div>

          {!!selectedPeriod.periodStatus && (
            <div className="row mt-2 mb-5">
              <Column>
                <InfiniteScroll
                  dataLength={checklistItems.length}
                  next={fetchNextItemSet}
                  hasMore={checklistItems.length < totalItems}
                  loader={<span>loading more checklist rows...</span>}
                  endMessage={<span></span>}
                >
                  <MonthlyChecklistItemTable
                    items={checklistItems}
                    onItemUpdated={handleItemUpdated}
                    onItemAdded={handleItemAdded}
                    onItemChanged={handleItemChanged}
                    onSaveComment={onSaveComment}
                    exportRef={(fn) => {
                      exportFunction = fn;
                    }}
                    sort={{
                      sortBy,
                      sortDirection,
                    }}
                    onSort={handleSort}
                    readOnly={
                      selectedPeriod.periodStatus !==
                      PeriodStatusLookupEnum.Open
                    }
                  />
                </InfiniteScroll>
              </Column>
            </div>
          )}
        </>
      )}
    </>
  );
};
