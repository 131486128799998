import { IChipOption } from 'components/ChipSelect';
import { JeFrequencyLookupWrapper } from 'components/Lookups/JeFrequencyLookups/JeFrequencyLookupWrapper';
import React from 'react';
import { LookupChipSelect } from '../LookupChipSelect';

interface IProps {
  includeDeleted?: boolean;
  selectedIds?: number[];
  onChange: (selectedOptions: readonly IChipOption[]) => void;
  placeholder?: string;
}

export const JeFrequencyChipSelect = (props: IProps): JSX.Element => {
  return (
    <JeFrequencyLookupWrapper>
      {({ queryDocument, queryVariables, extractLookups }) => (
        <LookupChipSelect
          extractLookups={extractLookups}
          queryDocument={queryDocument}
          queryVariables={queryVariables}
          onChange={props.onChange}
          selectedIds={props.selectedIds}
          placeholder={props.placeholder}
        />
      )}
    </JeFrequencyLookupWrapper>
  );
};
