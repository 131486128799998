import { DatePicker } from 'components/Form/DatePicker';
import { PlainText } from 'components/Form/PlainText';
import { AddItemIcon } from 'components/Icons/AddItemIcon';
import { RemoveIcon } from 'components/Icons/RemoveIcon';
import { isValidDate } from 'jec-common/lib/utils/date-utils';
import Column from 'layouts/components/Grid/Column';
import React, { useState } from 'react';
import { PeriodJeEmailReminderFragment } from 'types/graphql';

interface IProps {
  reminders: PeriodJeEmailReminderFragment[];

  handleAddNewReminder: (date: Date | null) => Promise<void>;
  handleChangeReminder: (id: number) => (date: Date | null) => void;
  handleRemoveReminder: (id: number) => () => void;
}

export const ReminderDates = (props: IProps): JSX.Element => {
  const [showNewReminderInput, setShowNewReminderInput] = useState(false);

  const handleShowAddReminderInput = (visible: boolean) => () => {
    setShowNewReminderInput(visible);
  };

  const handleAddNewReminder = (date: Date | null) => {
    if (isValidDate(date)) {
      props.handleAddNewReminder(date).then(() => {
        setShowNewReminderInput(false);
      });
    }
  };

  return (
    <>
      <div>
        {props.reminders.map((reminder) => (
          <div key={reminder.id} className="row mb-2">
            <Column>
              <DatePicker
                value={reminder.sendDate}
                onChange={props.handleChangeReminder(reminder.id)}
              />
            </Column>
            <Column width={1}>
              <PlainText>
                <RemoveIcon onClick={props.handleRemoveReminder(reminder.id)} />
              </PlainText>
            </Column>
          </div>
        ))}
        {!!showNewReminderInput && (
          <div className="row mb-2">
            <Column>
              <DatePicker onChange={handleAddNewReminder} value={null} />
            </Column>
            <Column width={1}>
              <PlainText>
                <RemoveIcon onClick={handleShowAddReminderInput(false)} />
              </PlainText>
            </Column>
          </div>
        )}
        <div className="row">
          <Column className="text-right">
            <PlainText
              className="pointer"
              onClick={handleShowAddReminderInput(true)}
            >
              Add new reminder date
            </PlainText>
          </Column>
          <Column width={1}>
            <PlainText>
              <AddItemIcon onClick={handleShowAddReminderInput(true)} />
            </PlainText>
          </Column>
        </div>
      </div>
    </>
  );
};
