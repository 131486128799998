import { useAuthContext } from 'auth/AuthContext';
import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface SecuredRouteProps extends RouteProps {
  role?: string;
}

const SecuredRoute = ({
  role,
  ...routeProps
}: SecuredRouteProps): JSX.Element => {
  const { isAuthenticated, logout, user } = useAuthContext();

  if (!isAuthenticated) {
    logout();
    return <></>;
  }

  if (role && !user?.roles.includes(role)) {
    logout();
    return <></>;
  }

  return <Route {...routeProps} />;
};

export default SecuredRoute;
