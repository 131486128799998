/**
 * Based on example at https://auth0.com/blog/react-tutorial-building-and-securing-your-first-app/
 */
import jwt_decode from 'jwt-decode';
import Axios, { AxiosInstance } from 'axios';
import { IAccessTokenPayload } from './IAccessTokenPayload';
import { apiBaseUrl } from 'utils/site-util';
import Cookies from 'js-cookie';

class AuthClient {
  private userId: number | null = null;
  private name: string | null = null;
  private roles: string[] = [];
  private accessToken?: string | null = null;
  private expiresAt?: number | null = null;

  private axiosClient: AxiosInstance;

  private timeout?: NodeJS.Timeout;

  private initialized = false;

  constructor() {
    this.getUserId = this.getUserId.bind(this);
    this.getRoles = this.getRoles.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.initialize = this.initialize.bind(this);

    const apiHost = apiBaseUrl();

    this.axiosClient = Axios.create({
      baseURL: apiHost,
      withCredentials: true, //needed so that axios will pass along cookies with requests
    });
  }

  getUserId = (): number | null => this.userId;
  getName = (): string | null => this.name;
  getRoles = (): string[] => this.roles;

  getAccessToken = () => this.accessToken;

  isAuthenticated = (): boolean =>
    !!(this.expiresAt && new Date().getTime() < this.expiresAt);

  signIn = () => {
    if (window.location.pathname !== '/login') {
      window.location.href = '/login';
    }
  };

  setSession = (authResult: {
    accessToken: string;
    accessTokenPayload: IAccessTokenPayload;
  }) => {
    this.accessToken = authResult.accessToken;
    this.userId = authResult.accessTokenPayload.sub;
    this.roles = authResult.accessTokenPayload.roles;
    this.name = authResult.accessTokenPayload.name;

    // set the time that the id token will expire at
    this.expiresAt = authResult.accessTokenPayload.exp * 1000;
  };

  signOut = async () => {
    // clear id token, profile, and expiration
    this.accessToken = null;
    this.userId = null;
    this.roles = [];
    this.expiresAt = null;
    this.name = null;

    let logoutUrl = `https://${process.env.REACT_APP_ONELOGIN_SITE_NAME}.onelogin.com/oidc/2/logout`;

    const idTokenCookieValue = Cookies.get('x-onelogin-id-token');
    if (!!idTokenCookieValue) {
      logoutUrl = `${logoutUrl}?post_logout_redirect_uri=${encodeURI(
        process.env.REACT_APP_WEBSITE_URL ?? '',
      )}&id_token_hint=${idTokenCookieValue}`;
    }

    window.location.href = logoutUrl;
  };

  silentRefresh = async () => {
    return this.axiosClient
      .post(`/auth/refresh`)
      .then(this.handleLoginOrRefreshResponse)
      .catch((reason) => {
        if (reason.response?.data?.statusCode === 401) {
          //this.signOut();
        }
      });
  };

  initialize = async () => {
    if (!this.initialized) {
      await this.silentRefresh();
      this.initialized = true;
    }
  };

  private handleLoginOrRefreshResponse = (response: {
    data: { accessToken: string };
  }) => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const { accessToken } = response.data;
    this.setSession({
      accessToken,
      accessTokenPayload: jwt_decode(accessToken) as IAccessTokenPayload,
    });

    if (this.expiresAt) {
      const nextRefreshTime = this.expiresAt - 5000;
      const millisecondsUntilNextRefresh = Math.min(
        60000,
        nextRefreshTime - new Date().getTime(),
      );

      this.timeout = setTimeout(() => {
        this.silentRefresh();
      }, millisecondsUntilNextRefresh);
    }

    return response;
  };
}

const authClient = new AuthClient();

export default authClient;
