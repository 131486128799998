import SwissArmySelect from 'components/SwissArmySelect';
import React from 'react';

export interface IChipAutosuggestOption {
  id: string;
  label: string;
}

export interface IChipAutosuggestProps {
  disabled?: boolean;
  availableOptions: (inputValue?: string) => Promise<IChipAutosuggestOption[]>;
  selectedOptions: IChipAutosuggestOption[];
  onSelect: (selectedOptions: ReadonlyArray<IChipAutosuggestOption>) => void;
}

export const ChipAutosuggest = (props: IChipAutosuggestProps): JSX.Element => {
  const handleLoadOptions = (
    inputValue: string,
    callback: (options: IChipAutosuggestOption[]) => void,
  ) => {
    props.availableOptions(inputValue).then(callback);
  };

  const handleChange = (
    value:
      | IChipAutosuggestOption
      | ReadonlyArray<IChipAutosuggestOption>
      | null
      | undefined,
  ): void => {
    if (!value) {
      props.onSelect([]);
      return;
    }

    if (Array.isArray(value)) {
      props.onSelect(value);
      return;
    }

    props.onSelect([value as IChipAutosuggestOption]);
  };

  return (
    <SwissArmySelect<IChipAutosuggestOption>
      readonly={props.disabled}
      className="flat"
      allowMultipleSelection={true}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.id}
      loadOptions={handleLoadOptions}
      value={props.selectedOptions}
      onChange={handleChange}
      placeholder=""
    />
  );
};
