import React from 'react';

import { ErrorResponse } from 'apollo-link-error';
import { ServerError } from 'apollo-link-http-common';
import {
  isForbiddenErrorResponse,
  isUnauthorizedErrorResponse,
} from './errorResponseHelper';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import './Alerts.scss';
import { AlertModal, IAlertModalProps } from './AlertModal';

export interface IAlertOptions {
  title?: string | null;
  label?: string | null;
  message?: React.ReactElement | string | null;
  onConfirm?: () => Promise<void> | null;
  onCancel?: () => Promise<void> | null;
  variant?: 'danger' | 'warning' | 'success' | 'primary';
}

export interface IErrorsAlertOptions extends IAlertOptions {
  errors: string[];
}

export interface ISuccessAlertOptions extends IAlertOptions {
  timeout?: number | null;
}

export interface IInfoAlertOptions extends IAlertOptions {}

export interface IApolloErrorOptions extends IAlertOptions {
  error: ErrorResponse;
}

const renderModal = (props: IAlertModalProps) => {
  return <AlertModal {...props} />;
};

const useModalAlerts = (
  setAlertModal: (modal: React.ReactNode | null) => void,
): any => {
  const clickAndClose =
    (onClick?: () => Promise<void> | null, resolve?: () => void) => () => {
      if (onClick) {
        onClick()?.then(resolve);
      } else if (resolve) {
        resolve();
      }
      setAlertModal(null);
    };

  const error = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'An error has been encountered',
        icon: faExclamationTriangle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const errors = (props: IErrorsAlertOptions) => {
    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        <ul>
          {props.errors.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label ?? 'The following errors have been encountered',
        icon: faExclamationTriangle,
        body,
        onCloseClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const apolloError = (props: IApolloErrorOptions) => {
    const errorResponse = props.error as any;
    const { graphQLErrors, networkError } = errorResponse;

    if (isUnauthorizedErrorResponse(errorResponse)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Unauthorized',
          label: props.label,
          body:
            props.message ?? graphQLErrors?.length
              ? graphQLErrors[0].message
              : 'You must log in to access this resource',
          onOkClick: clickAndClose(),
          variant: 'danger',
        }),
      );

      return;
    } else if (isForbiddenErrorResponse(errorResponse)) {
      setAlertModal(
        renderModal({
          title: props.title ?? 'Forbidden',
          label: props.label,
          body:
            props.message ?? props.message ?? graphQLErrors?.length
              ? graphQLErrors[0].message
              : 'You do not have permission to access this resource',
          onOkClick: clickAndClose(),
          variant: 'danger',
        }),
      );

      return;
    }

    // The networkError can be one of 3 different types.
    // A ServerError is encountered for example if a mutation is
    // sent to the server but maybe an invalid input field is specified
    // or a required field was not provided or if there is no subfields
    // specified to return in the result.
    //const error = networkError as Error;
    const serverError = networkError as ServerError;

    const authError: string | null = null;
    const validationErrors: string[] = [];
    if (graphQLErrors) {
      validationErrors.push(
        ...graphQLErrors
          .flatMap((error: any) => {
            const message =
              error.extensions?.response?.message ?? error.message;
            if (Array.isArray(message)) {
              return error.extensions?.response?.message?.filter(
                (message: string) => !!message,
              );
            } else {
              return message;
            }
          })
          .filter((m: any) => !!m),
      );
    }

    if (validationErrors.length) {
      errors({
        errors: validationErrors,
        label: props.label,
      });
      return;
    }

    if ((props.error as any).stack) {
      console.error((props.error as any).stack);
    }

    const body = (
      <div>
        {props.message && <p>{props.message}</p>}
        {(props.error as any).message && <p>{(props.error as any).message}</p>}
        {authError && <p>{authError}</p>}
        {!!(validationErrors && validationErrors.length) && (
          <>
            <label>Validation Errors</label>
            <ul>
              {validationErrors.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </>
        )}
        {!!(graphQLErrors && graphQLErrors.length) && (
          <ul style={{ display: 'none' }}>
            {graphQLErrors.map((graphQLError: any, index: number) => (
              <li key={index}>{graphQLError.message}</li>
            ))}
          </ul>
        )}
        {!!serverError?.result?.errors && (
          <>
            <label>Server Errors</label>
            <ul>
              {serverError.result.errors.map(
                (error: { message: string }, index: number) => (
                  <li key={index}>{error.message}</li>
                ),
              )}
            </ul>
          </>
        )}

        {networkError?.statusCode && <p>status: {networkError?.statusCode}</p>}
      </div>
    );

    setAlertModal(
      renderModal({
        title: props.title ?? 'Error',
        label: props.label,
        body,
        icon: faExclamationTriangle,
        onOkClick: clickAndClose(),
        variant: 'danger',
      }),
    );
  };

  const warning = (props: IAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Warning',
        icon: faExclamationCircle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'warning',
      }),
    );
  };

  const success = (props: ISuccessAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Success',
        icon: faCheckCircle,
        label: 'The operation was successful',
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'success',
      }),
    );

    if (props.timeout) {
      setTimeout(() => {
        setAlertModal(null);
      }, props.timeout);
    }
  };

  const info = (props: IInfoAlertOptions) => {
    setAlertModal(
      renderModal({
        title: props.title ?? 'Note',
        icon: faCheckCircle,
        body: props.message ?? '',
        onCloseClick: clickAndClose(),
        variant: 'primary',
      }),
    );
  };

  const confirm = (props: IAlertOptions) => {
    let resolve;
    let reject;
    const result = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });

    setAlertModal(
      renderModal({
        title: props.title ?? 'Confirmation',
        icon: faQuestionCircle,
        body: props.message ?? '',
        onOkClick: clickAndClose(props.onConfirm, resolve),
        onCancelClick: clickAndClose(props.onCancel, reject),
        variant: props.variant ?? 'primary',
      }),
    );

    return result;
  };

  return {
    apolloError,
    confirm,
    error,
    errors,
    success,
    info,
    warning,
  };
};

export default useModalAlerts;
