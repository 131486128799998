import React from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';

interface IProps {
  label?: string;
  variant: string;
  className?: string;
  isMultiLine?: boolean;
  size?: 'sm' | 'lg';
  onClick?: () => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const Button = (props: IProps): JSX.Element => {
  let className = `text-uppercase font-weight-bold ${props.className ?? ''}`;
  if (props.isMultiLine) {
    className = `${className} ${props.isMultiLine ? 'multiline' : ''}`.trim();
  }
  return (
    <BootstrapButton
      variant={props.variant}
      className={className}
      size={props.size ?? 'lg'}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children ?? props.label}
    </BootstrapButton>
  );
};
